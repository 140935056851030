<script>
import feather from 'feather-icons';

export default {
	data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div>
		<router-view :theme="appTheme" />
	</div>
</template>

<style>

</style>
