<template>
  <div>
    <!-- Sidebar -->
    <aside :class="sidebarClasses">
      <div class="flex justify-between items-center p-4">
        <router-link to="/auth/">
          <img
            v-if="theme === 'light'"
            src="@/assets/images/Logo-white.png"
            class="w-36"
            alt="Logo Light"
          />
          <img
            v-else
            src="@/assets/images/Logo-dark.png"
            class="w-36"
            alt="Logo Dark"
          />
        </router-link>
        <!-- Close Sidebar Button (Visible on md and larger) -->
        <button
          v-if="!isDesktop"
          @click="toggleSidebar"
          class="text-gray-200 hover:text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </button>
      </div>
      <nav class="mt-6 text-white">
        <ul>
          <li>
            <router-link
              to="/auth"
              class="font-general-medium block text-left text-lg font-medium text-ternary-light hover:text-pink-light mx-4 mb-2 sm:py-2"
              @click="toggleSidebar"
            >
              <div class="flex gap-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-pie-chart"
                  >
                    <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                    <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                  </svg>
                </div>
                <div>Dashboard</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              to="/auth/add"
              class="font-general-medium block text-left text-lg font-medium text-ternary-light hover:text-pink-light mx-4 mb-2 sm:py-2"
              @click="toggleSidebar"
            >
              <div class="flex gap-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                    />
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                    />
                  </svg>
                </div>
                <div>Hinzufügen</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              to="/auth/links"
              class="font-general-medium block text-left text-lg font-medium text-ternary-light hover:text-pink-light mx-4 mb-2 sm:py-2"
              @click="toggleSidebar"
            >
              <div class="flex gap-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-link-45deg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"
                    />
                    <path
                      d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"
                    />
                  </svg>
                </div>
                <div>Links</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              to="/auth/pricing"
              class="font-general-medium block text-left text-lg font-medium text-ternary-light hover:text-pink-light mx-4 mb-2 sm:py-2"
              @click="toggleSidebar"
            >
              <div class="flex gap-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path
                      d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                    ></path>
                  </svg>
                </div>
                <div>Plan</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              to="/"
              class="font-general-medium block text-left text-lg font-medium text-ternary-light hover:text-pink-light mx-4 mb-2 sm:py-2"
              @click="toggleSidebar"
            >
              <div class="flex gap-3">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-settings"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path
                      d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                    ></path>
                  </svg>
                </div>
                <div>Einstellungen</div>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="fixed bottom-2 text-white">
        <LanguageSelector />
      </div>
    </aside>

    <!-- Mobile Menu Toggle Button -->
    <button
      v-if="!isDesktop"
      @click="toggleSidebar"
      class="fixed top-2 right-4 z-30 p-3 dark:text-white text-black"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </button>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useMediaQuery } from "@vueuse/core";
import LanguageSelector from "../../lang/LanguageSelector";

export default {
  components: {
    LanguageSelector,
  },
  setup() {
    const isOpen = ref(false);
    const isDesktop = useMediaQuery("(min-width: 768px)");

    const sidebarClasses = computed(() => ({
      "fixed top-0 left-0 z-40 w-full sm:w-64 h-full bg-secondary-dark shadow-md transform transition-transform": true,
      "-translate-x-full": !isOpen.value && !isDesktop.value,
      "translate-x-0": isOpen.value || isDesktop.value,
    }));

    const toggleSidebar = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      sidebarClasses,
      toggleSidebar,
      isDesktop,
    };
  },
};
</script>

<style scoped>
aside {
  transition: transform 0.3s ease;
}
</style>
