<template>
  <div class="flex flex-col items-center justify-center md:mt-24">
    <CardDefault class="max-w-xl">
      <template v-slot:header-left>Register</template>
      <template v-slot:main>
        <form @submit.prevent="register">
          <div class="p-2">
            <FormInput
              class="py-1"
              label="Firstname"
              inputType="text"
              v-model="firstname"
              inputIdentifier="firstname"
              :error="firstnameError"
            />
            <FormInput
              class="py-1"
              label="Lastname"
              inputType="text"
              v-model="lastname"
              inputIdentifier="lastname"
              :error="lastnameError"
            />
            <FormInput
              class="py-1"
              label="Mail Adresse"
              inputType="email"
              v-model="email"
              inputIdentifier="mail"
              :error="emailError"
            />
            <FormInput
              class="py-1"
              label="Passwort"
              inputType="password"
              v-model="password"
              inputIdentifier="password"
              :error="passwordError"
            />
            <FormInput
              class="py-1"
              label="Passwort wiederholen"
              inputType="password"
              v-model="passwordwiederholen"
              inputIdentifier="passwordwiederholen"
              :error="passwordwiederholenError"
            />
            <FormCheckbox v-model="agb" inputIdentifier="terms" label="Ich akzeptiere die AGB und Datenschutzbestimmungen von
                QrLine.io" :error="error" />
            <ButtonDefaultColor type="submit" class="w-full mt-5">
              <div v-if="!loading">Registrieren</div>
              <div v-else>
                <div class="flex gap-2 items-center justify-center">
                  <SpinnerWhiteSmall></SpinnerWhiteSmall>Loading...
                </div>
              </div>
            </ButtonDefaultColor>
          </div>
        </form>
      </template>
    </CardDefault>
  </div>
</template>

<script>
import { apiClient } from "@/components/config/api.js";
import CardDefault from "@/components/reusable/CardDefault.vue";
import FormInput from "@/components/reusable/FormInput.vue";

export default {
  name: "Register",
  components: {
    CardDefault,
    FormInput,
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      passwordwiederholen: "",
      agb: false,
      loading: false,
      firstnameError: "",
      lastnameError: "",
      emailError: "",
      passwordError: "",
      passwordwiederholenError: "",
    };
  },
  methods: {
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    validatePassword(password) {
      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordPattern.test(password);
    },
    async register() {
      this.firstnameError = "";
      this.lastnameError = "";
      this.emailError = "";
      this.passwordError = "";
      this.passwordwiederholenError = "";

      if (!this.firstname) {
        this.firstnameError = "Firstname is required.";
      }
      if (!this.lastname) {
        this.lastnameError = "Lastname is required.";
      }
      if (!this.email || !this.validateEmail(this.email)) {
        this.emailError = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
      }
      if (!this.password || !this.validatePassword(this.password)) {
        this.passwordError =
          "Das Passwort muss mindestens 8 Zeichen lang sein und eine Mischung aus Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen enthalten.";
      }
      if (this.password !== this.passwordwiederholen) {
        this.passwordwiederholenError = "Passwörter stimmen nicht überein.";
      }
      if (!this.agb) {
        this.$toast.open({
          message: "Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen.",
          type: "error",
          duration: 3000,
        });
        return;
      }

      if (
        this.firstnameError ||
        this.lastnameError ||
        this.emailError ||
        this.passwordError ||
        this.passwordwiederholenError
      ) {
        return;
      }

      this.loading = true;
      try {
        const response = await apiClient.post(
          `/auth/signupInvite/`,
          {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            password: this.password,
          }
        );

        if (response.status === 200) {
          this.$router.push("/auth/login");
        }
        this.loading = false;
      } catch (error) {
        console.error("Registrierung fehlgeschlagen:", error);
        this.loading = false;
        this.$toast.open({
          message:
            error.response.data.message || "Registrierung fehlgeschlagen.",
          type: "error",
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
