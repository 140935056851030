<template>
  <div class="w-full">
    <div
      class="leading-loose max-w-xl p-7 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left"
    >
      <p
        class="font-general-medium text-primary-dark dark:text-primary-light text-2xl mb-8"
      >
        QR-Code erzeugen
      </p>

      <!-- Stepper Navigation mit Icons und Fortschrittsbalken -->
      <div class="flex justify-between items-center mb-6 relative">
        <!-- Fortschrittslinie -->
        <div
          class="absolute left-0 right-0 h-1 bg-gray-300 z-0 top-1/2 transform -translate-y-1/2"
        ></div>

        <!-- Fortschrittslinie für fertige Schritte -->
        <div
          class="absolute left-0 h-1 z-10 bg-pink-light"
          :style="stepProgressStyle"
        ></div>

        <!-- Step 1 -->
        <div class="flex flex-col items-center z-20">
          <div
            class="w-10 h-10 flex items-center justify-center rounded-full border-4"
            :class="{
              'border-pink-light bg-white': currentStep >= 1,
              'border-gray-300 bg-gray-200': currentStep < 1,
            }"
            @click="currentStep = 1"
          >
            <i class="bi bi-link-45deg"></i>
            <!-- Icon für Schritt 1 -->
          </div>
        </div>

        <!-- Step 2 -->
        <div class="flex flex-col items-center z-20">
          <div
            class="w-10 h-10 flex items-center justify-center rounded-full border-4"
            :class="{
              'border-pink-light bg-white': currentStep >= 2,
              'border-gray-300 bg-gray-200': currentStep < 2,
            }"
            @click="currentStep = 2"
          >
            <i class="bi bi-paint-bucket"></i>
            <!-- Icon für Schritt 2 -->
          </div>
        </div>

        <!-- Step 3 -->
        <div class="flex flex-col items-center z-20">
          <div
            class="w-10 h-10 flex items-center justify-center rounded-full border-4"
            :class="{
              'border-pink-light bg-white': currentStep >= 3,
              'border-gray-300 bg-gray-200': currentStep < 3,
            }"
            @click="currentStep = 3"
          >
            <i class="bi bi-qr-code"></i>
            <!-- Icon für Schritt 3 -->
          </div>
        </div>
      </div>

      <!-- Step 1: Auswahl des QR-Code Typs -->
      <div v-if="currentStep === 1">
        <p class="font-general-regular text-lg dark:text-white mb-4">
          QR-Code Typ wählen:
        </p>
        <div class="flex flex-wrap gap-2 mb-4">
          <div
            v-for="tab in ['URL', 'vCard', 'Text', 'E-Mail', 'WiFi']"
            :key="tab"
          >
            <ButtonDefaultColor
              v-if="selectedTab === tab"
              @click="selectedTab = tab"
              >{{ tab }}</ButtonDefaultColor
            >
            <ButtonDefaultTrans v-else @click="selectedTab = tab">{{
              tab
            }}</ButtonDefaultTrans>
          </div>
        </div>

        <!-- Formularfelder je nach Auswahl -->
        <form class="space-y-7">
          <!-- URL -->
          <div v-if="selectedTab === 'URL'">
            <FormInput
              label="URL"
              inputIdentifier="url"
              v-model="qrCodeText.url"
            />
          </div>
          <!-- vCard -->
          <div v-if="selectedTab === 'vCard'">
            <FormInput
              label="Full Name"
              inputIdentifier="name"
              v-model="qrCodeText.name"
            />
            <FormInput
              label="Phone Number"
              inputIdentifier="phone"
              v-model="qrCodeText.phone"
            />
            <FormInput
              label="Email Address"
              inputIdentifier="email"
              v-model="qrCodeText.email"
            />
            <FormInput
              label="Company"
              inputIdentifier="company"
              v-model="qrCodeText.company"
            />
            <FormInput
              label="Address"
              inputIdentifier="address"
              v-model="qrCodeText.address"
            />
          </div>
          <!-- Text -->
          <div v-if="selectedTab === 'Text'">
            <FormTextarea
              label="Text"
              textareaIdentifier="text"
              v-model="qrCodeText.text"
            />
          </div>
          <!-- E-Mail -->
          <div v-if="selectedTab === 'E-Mail'">
            <FormInput
              label="Empfänger E-Mail"
              inputIdentifier="email"
              v-model="qrCodeText.email"
            />
            <FormInput
              label="Betreff"
              inputIdentifier="subject"
              v-model="qrCodeText.subject"
            />
            <FormTextarea
              label="Nachricht"
              textareaIdentifier="message"
              v-model="qrCodeText.message"
            />
          </div>
          <!-- WiFi -->
          <div v-if="selectedTab === 'WiFi'">
            <FormInput
              label="SSID"
              inputIdentifier="ssid"
              v-model="qrCodeText.ssid"
            />
            <FormInput
              label="Passwort"
              inputIdentifier="password"
              v-model="qrCodeText.password"
            />
            <FormInput
              label="Verschlüsselung"
              inputIdentifier="encryption"
              v-model="qrCodeText.encryption"
            />
          </div>
        </form>
        <div class="flex justify-between mt-5">
          <div></div>
          <div>
            <ButtonDefaultColor @click="nextStep">Weiter</ButtonDefaultColor>
          </div>
        </div>
      </div>

      <!-- Step 2: Logo und Farbauswahl -->
      <div v-if="currentStep === 2">
        <p class="font-general-regular text-lg dark:text-white mb-4">
          Eigene Farbe wählen:
        </p>
        <div class="flex justify-between items-center">
          <!-- Benutzerdefinierte Farbauswahl -->
          <div class="">
            <span class="text-gray-500 dark:text-gray-300"
              >Benutzerdefinierte Farbe</span
            >
            <div class="position-relative">
              <!-- Unsichtbarer Farbwähler -->
              <input
                type="color"
                id="colorPicker"
                v-model="qrCodeColor"
                ref="colorInput"
                class="d-none"
              />

              <!-- Button mit Bootstrap-Icon -->
              <button
                type="button"
                class="btn btn-outline-secondary"
                @click="$refs.colorInput.click()"
              >
                <i class="bi bi-palette text-2xl"></i>
                <!-- Bootstrap Icon für die Farbpalette -->
              </button>
            </div>
          </div>

          <!-- Vordefinierte Farben -->
          <div class="items-center">
            <p class="block text-gray-700 dark:text-gray-300">
              Oder vordefinierte Farben:
            </p>
            <button
              v-for="color in predefinedColors"
              :key="color"
              :style="{ backgroundColor: color }"
              @click="selectColor(color)"
              class="w-10 h-10 rounded-full border-2 cursor-pointer mr-2"
              :class="
                qrCodeColor === color ? 'border-pink-500' : 'border-gray-300'
              "
            ></button>
          </div>
        </div>
        <!-- Logo Upload als Dropzone -->
        <p class="font-general-regular text-md mb-2 dark:text-white">
          Logo hochladen (PNG):
        </p>
        <div
          class="relative w-full h-40 border-2 border-dashed border-gray-300 rounded-lg flex justify-center items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <!-- Datei-Input, das durch triggerFileInput() geöffnet wird -->
          <input
            type="file"
            accept="image/png"
            class="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
            @change="onFileChange"
            ref="fileInput"
          />
          <div class="text-center" @click="triggerFileInput">
            <i class="bi bi-upload text-2xl text-gray-500"></i>
            <p class="text-gray-500 dark:text-gray-300 mt-2">
              PNG hier ablegen oder klicken zum Hochladen
            </p>
          </div>
        </div>

        <!-- Zeige das hochgeladene Logo an -->
        <div v-if="logoFile" class="mt-4">
          <p class="font-general-regular text-md dark:text-white">
            Hochgeladenes Logo:
          </p>
          <img :src="logoFile" alt="Logo" class="w-20 h-20 object-contain" />
        </div>
        <div class="flex justify-between mt-5">
          <ButtonDefaultColor @click="backStep()">Zurück</ButtonDefaultColor>
          <ButtonDefaultColor @click="nextStep(), generateQRCode()"
            >Weiter</ButtonDefaultColor
          >
        </div>
      </div>

      <!-- Step 3: QR-Code erstellen -->
      <div v-if="currentStep === 3">
        <div v-if="qrCodeData" class="mt-6">
          <p
            class="font-general-medium text-lg text-primary-dark dark:text-primary-light"
          >
            Ihr QR-Code:
          </p>
          <div>
            <img :src="qrCodeData" alt="QR Code" class="rounded-lg" />
          </div>

          <div class="flex justify-between mt-5">
            <ButtonDefaultColor @click="backStep()">Zurück</ButtonDefaultColor>
            <div>
              <a
                :href="qrCodeData"
                download="qrcode.png"
                class="flex justify-center items-center w-full text-lg border border-indigo-200 dark:border-ternary-dark py-2.5 sm:py-3 px-5 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-pink-light text-gray-500 hover:text-white duration-500"
                aria-label="Download Resume"
              >
                <i class="bi bi-arrow-down-circle w-5 sm:w-6 duration-100"></i>
                <span
                  class="text-sm sm:text-lg font-general-medium duration-100"
                  >Download</span
                ></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import QRCode from "qrcode";
import FormInput from "../../reusable/FormInput.vue";
import FormTextarea from "../../reusable/FormTextarea.vue";

export default {
  components: { FormInput, FormTextarea },
  setup() {
    const currentStep = ref(1);
    const selectedTab = ref("URL");
    const qrCodeData = ref("");
    const qrCodeColor = ref("#000000");
    const logoFile = ref(null);
    const fileInput = ref(null);
    const predefinedColors = ["#000000", "#ff0000", "#007bff", "#ff6600"]; // Vordefinierte Farben

    const qrCodeText = ref({
      url: "https://",
      name: "",
      phone: "",
      email: "",
      company: "",
      address: "",
      text: "",
      subject: "",
      message: "",
      ssid: "",
      password: "",
      encryption: "",
    });

    // Berechnung der Breite des Fortschrittsbalkens als Style-Objekt
    // Berechnung der Breite des Fortschrittsbalkens als Style-Objekt
    const stepProgressStyle = computed(() => {
      if (currentStep.value === 1) return { width: "0%" };
      if (currentStep.value === 2) return { width: "50%" };
      if (currentStep.value === 3) return { width: "100%" };

      // Standard-Rückgabe, falls keine der Bedingungen zutrifft
      return { width: "0%" };
    });

    const triggerFileInput = () => {
      fileInput.value.click();
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = (e) => {
          logoFile.value = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Bitte ein PNG-Bild hochladen.");
      }
    };

    const handleDrop = (event) => {
      const file = event.dataTransfer.files[0];
      if (file && file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = (e) => {
          logoFile.value = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Bitte ein PNG-Bild hochladen.");
      }
    };

    const nextStep = () => {
      if (currentStep.value < 3) {
        currentStep.value += 1;
      }
    };

    const backStep = () => {
      if (currentStep.value > 1) {
        currentStep.value -= 1;
      }
    };

    // Funktion zur Auswahl einer vordefinierten Farbe
    const selectColor = (color) => {
      qrCodeColor.value = color; // Setzt die ausgewählte Farbe
    };

    const generateQRCode = async () => {
      let dataToEncode = "";

      switch (selectedTab.value) {
        case "URL":
          dataToEncode = qrCodeText.value.url || "";
          break;
        case "vCard":
          dataToEncode = `BEGIN:VCARD\nVERSION:3.0\nFN:${qrCodeText.value.name}\nORG:${qrCodeText.value.company}\nTEL:${qrCodeText.value.phone}\nEMAIL:${qrCodeText.value.email}\nADR:${qrCodeText.value.address}\nEND:VCARD`;
          break;
        case "Text":
          dataToEncode = qrCodeText.value.text || "";
          break;
        case "E-Mail":
          dataToEncode = `mailto:${
            qrCodeText.value.email
          }?subject=${encodeURIComponent(
            qrCodeText.value.subject
          )}&body=${encodeURIComponent(qrCodeText.value.message)}`;
          break;
        case "WiFi":
          dataToEncode = `WIFI:S:${qrCodeText.value.ssid};T:${qrCodeText.value.encryption};P:${qrCodeText.value.password};;`;
          break;
      }

      if (!dataToEncode) {
        alert(
          "Bitte füllen Sie das Formular aus, bevor Sie einen QR-Code generieren."
        );
        return;
      }

      try {
        const qrCanvas = document.createElement("canvas");
        await QRCode.toCanvas(qrCanvas, dataToEncode, {
          width: 200,
          margin: 1,
          color: {
            dark: qrCodeColor.value,
            light: "#FFFFFF",
          },
        });

        if (logoFile.value) {
          const ctx = qrCanvas.getContext("2d");
          const logoImage = new Image();
          logoImage.src = logoFile.value;
          logoImage.onload = () => {
            const logoSize = qrCanvas.width * 0.3;
            const x = (qrCanvas.width - logoSize) / 2;
            const y = (qrCanvas.height - logoSize) / 2;
            ctx.drawImage(logoImage, x, y, logoSize, logoSize);
            qrCodeData.value = qrCanvas.toDataURL();
          };
        } else {
          qrCodeData.value = qrCanvas.toDataURL();
        }
      } catch (err) {
        console.error("QR-Code konnte nicht generiert werden:", err);
      }
    };

    return {
      currentStep,
      selectedTab,
      qrCodeData,
      qrCodeText,
      qrCodeColor,
      fileInput,
      triggerFileInput,
      onFileChange,
      handleDrop,
      backStep,
      nextStep,
      generateQRCode,
      predefinedColors,
      selectColor,
      stepProgressStyle,
    };
  },
};
</script>

<style scoped>
.color-input {
  -webkit-appearance: none;
  border: none; /* entfernt die Standard-Borderrahmen */
  width: 40px; /* Breite des Kreises */
  height: 40px; /* Höhe des Kreises */
  border-radius: 50%; /* Macht den Kreis rund */
  cursor: pointer; /* Ändert den Cursor beim Hover */
  background: none;
  padding: 0;
}

/* Entfernt die Standard-Styling für Chrome/Safari */
.color-input::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none;
}

.color-input::-webkit-color-swatch {
  border: none;
  border-radius: 50%; /* Runde Form */
}

/* Entfernt die Standard-Styling für Firefox */
.color-input::-moz-color-swatch {
  border: none;
  border-radius: 50%; /* Runde Form */
}
</style>
