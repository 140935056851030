<template>
  <div class="flex flex-col items-center justify-center md:mt-24">
    <CardDefault class="max-w-xl">
      <template v-slot:header-left>Login</template>
      <template v-slot:main>
        <form @submit.prevent="login">
          <div class="p-2">
            <FormInput
              class="py-1"
              label="Mail Adresse"
              inputType="email"
              v-model="email"
              inputIdentifier="mail"
              :error="emailError"
            />
            <FormInput
              class="py-1"
              label="Passwort"
              inputType="password"
              v-model="password"
              inputIdentifier="password"
              :error="passwordError"
            />
            <router-link to="/register">
              <p
                class="font-general-regular text-left text-secondary-dark dark:text-ternary-light hover:text-pink-light dark:hover:text-pink-light text-md font-normal pt-2 mb-4"
              >
                Ich habe noch keinen Account
              </p>
            </router-link>
            <router-link to="/password/request">
              <p
                class="font-general-regular text-left text-secondary-dark dark:text-ternary-light hover:text-pink-light dark:hover:text-pink-light text-md font-normal mb-4"
              >
                Passwort vergessen?
              </p>
            </router-link>
            <ButtonDefaultColor type="submit" class="w-full mt-5">
              <div v-if="!loading">Einloggen</div>
              <div v-else>
                <div class="flex gap-2 items-center justify-center">
                  <SpinnerWhiteSmall></SpinnerWhiteSmall>Loading...
                </div>
              </div>
            </ButtonDefaultColor>
          </div>
        </form>
      </template>
    </CardDefault>
  </div>
</template>

<script>
import { apiClient } from "@/components/config/api.js";
import CardDefault from "@/components/reusable/CardDefault.vue";
import FormInput from "@/components/reusable/FormInput.vue";

export default {
  name: "Login",
  components: {
    CardDefault,
    FormInput,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      emailError: '',
      passwordError: '',
    };
  },
  methods: {
    validateEmail(email) {
      // Basic email validation regex
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async login() {
      this.emailError = "";
      this.passwordError = "";
      if (!this.email || !this.validateEmail(this.email)) {
        this.emailError = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
        return;
      }
      if (!this.password) {
        this.passwordError = "Passwort darf nicht leer sein.";
        return;
      }
      this.loading = true;
      try {
        const response = await apiClient.post(
          `/auth/signin/`,
          {
            email: this.email,
            password: this.password,
          }
        );

        // Optional: Hier kannst du den Erfolg verarbeiten, z.B. Weiterleitung oder Benachrichtigung
        if (response.status === 200) {
          // Login erfolgreich, z.B. Weiterleitung zur Startseite
          this.$router.push("/auth/dashboard");
        }
        this.loading = false;
      } catch (error) {
        console.error("Login fehlgeschlagen:", error);
        this.loading = false;
        this.$toast.open({
        message: error.response.data.message,
        type: 'error',
        duration: 3000,
      });
        // Optional: Fehlerbehandlung hier
      }
    },
  },
};
</script>

<style scoped></style>
