<script>
import { apiClient } from "@/components/config/api.js";
import CardDefault from "@/components/reusable/CardDefault.vue";

export default {
  name: "Profil",
  components: {
    CardDefault,
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      oldpassword: "",
      newpassword1: "",
      newpassword2: "",
      firstnameError: null,
      lastnameError: null,
      loading: true,
      item: null,
    };
  },
  mounted() {
    this.fetchItem();
  },
  methods: {
    async fetchItem() {
      try {
        const response = await apiClient.get("profils/");
        this.item = response.data;
        this.firstname = this.item.firstname || "";
        this.lastname = this.item.lastname || "";
        this.email = this.item.email || "";
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async saveProfile() {
      try {
        const response = await apiClient.put("profils/", {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
        });
        console.log("Profile updated", response.data);
        this.$toast.open({
          message: response.data.message,
          type: "success",
          position: "bottom-right",
        });
      } catch (error) {
        console.error("Error saving profile", error);
        this.$toast.open({
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Fehler beim Speichern des Links",
          type: "error",
          position: "bottom-right",
        });
      }
    },
    changePassword() {
      // Überprüfe, ob das alte Passwort korrekt eingegeben wurde

      // Überprüfe, ob das neue Passwort zweimal korrekt eingegeben wurde
      if (this.newpassword1 !== this.newpassword2) {
        this.$toast.open({
          message: "Die neuen Passwörter stimmen nicht überein.",
          type: "error",
          position: "bottom-right",
        });
        return;
      }

      // Führe die API-Anfrage zum Ändern des Passworts durch
      apiClient
        .put("profils/changePassword/", {
          oldPassword: this.oldpassword,
          newPassword: this.newpassword1,
        })
        .then(() => {
          // Erfolgreiche Antwort vom Backend
          this.$toast.open({
            message: "Passwort erfolgreich geändert.",
            type: "success",
            position: "bottom-right",
          });
          // Zurücksetzen der Eingabefelder
          this.oldpassword = "";
          this.newpassword1 = "";
          this.newpassword2 = "";
        })
        .catch((error) => {
          // Fehler beim Ändern des Passworts
          this.$toast.open({
            message: "Fehler beim Ändern des Passworts.",
            type: "error",
            position: "bottom-right",
          });
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <div>
    <CardDefault class="w-full">
      <template v-slot:header-left>Dein Profil</template>
      <template v-slot:main>
        <GridCols2>
          <template v-slot:left>
            <FormInput
              class="py-1"
              label="Firstname"
              inputType="text"
              v-model="firstname"
              inputIdentifier="text"
              :error="firstnameError"
            />
          </template>
          <template v-slot:right>
            <FormInput
              class="py-1"
              label="Lastname"
              inputType="text"
              v-model="lastname"
              inputIdentifier="text"
              :error="lastnameError"
            />
          </template>
        </GridCols2>
        <FormInput
          class="py-1"
          label="Mail Adresse"
          inputType="email"
          v-model="email"
          inputIdentifier="email"
        />
        <div class="py-2">
          <GridCols2>
            <template v-slot:left>
              <ButtonDefaultColor @click="saveProfile">
                Profil speichern
              </ButtonDefaultColor>
            </template>
            <template v-slot:right>
              <ButtonDefaultTrans>Profil löschen</ButtonDefaultTrans>
            </template>
          </GridCols2>
        </div>
      </template>
    </CardDefault>
    <CardDefault class="w-full mt-5 mb-10">
      <template v-slot:header-left>Passwort ändern</template>
      <template v-slot:main>
        <GridCols2>
          <template v-slot:left>
            <FormInput
              class="py-1"
              label="Altes Passwort"
              inputIdentifier="oldpassword"
              inputType="password"
              v-model="oldpassword"
            />
          </template>
          <template v-slot:right> </template>
        </GridCols2>
        <GridCols2>
          <template v-slot:left>
            <FormInput
              class="py-1"
              label="Neues Passwort"
              inputIdentifier="newpassword1"
              inputType="password"
              v-model="newpassword1"
            />
          </template>
          <template v-slot:right>
            <FormInput
              class="py-1"
              label="Neues Passwort wiederholen"
              inputIdentifier="newpassword2"
              inputType="password"
              v-model="newpassword2"
            />
          </template>
        </GridCols2>
        <div class="py-2">
          <ButtonDefaultColor class="w-full mt-5" @click="changePassword">
            Passwort ändern
          </ButtonDefaultColor>
        </div></template
      >
    </CardDefault>
  </div>
</template>

<style scoped></style>
