<template>
  <a @click="openModal()"><slot name="DefaultText"></slot></a>
  <transition name="fade">
  <div
    v-if="showModal"
    @click.self="closeModal"
    class="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
  >
    <div class="flex items-center justify-center min-h-screen">
      <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-secondary-dark">
          <!-- Modal header -->
          <div
            class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              <slot name="HeaderText"></slot>
            </h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              @click="closeModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <slot name="Body"></slot>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          >
            <slot name="Footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>

export default {
  name: "ModelDefault",
  data() {
    return {
      showModal: false,
    };
  },
  components() {
  },
  props: {},
  methods: {
    closeModal() {
      this.showModal = false;
      document.removeEventListener("keydown", this.handleEsc);
    },
    openModal() {
      this.showModal = true;
      document.addEventListener("keydown", this.handleEsc);
    },
    handleEsc(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closeModal();
      }
    },
  },
  beforeUnmount() {
    // Sicherstellen, dass der Listener entfernt wird, falls die Komponente zerstört wird
    document.removeEventListener("keydown", this.handleEsc);
  },
};
</script>

<style scoped>
.modal {
  background-color: rgba(0, 0, 0, 0.5); /* Dunkler Hintergrund */
}
</style>
