<template>
  <div class="tracking-charts">
    <div class="flex justify-between py-5">
      <p
        class="font-general-medium text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none"
        :class="isDarkMode ? 'text-white' : 'text-gray-400'"
      >
        Statistik
      </p>
      <div class="flex gap-2">
        <div>
          <p
        class="font-general-medium text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none"
        :class="isDarkMode ? 'text-white' : 'text-gray-400'"
      >
      {{ clickstotalClicks }} Klicks
      </p>
        </div>
      </div>
    </div>
    <CardDefault class="my-5"
      ><template v-slot:header-left>
        <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
          >Klicks pro Tag</span
        > </template
      ><template v-slot:main>
        <div class="chart-container">
          <canvas
            id="clicksPerDayChart"
            class="max-h-72"
          ></canvas></div></template
    ></CardDefault>
    <GridCols4
      ><template v-slot:one>
        <CardDefault
          ><template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Browsers Klicks</span
            > </template
          ><template v-slot:main>
            <div class="chart-container">
              <canvas
                id="clicksByBrowserChart"
              ></canvas></div></template></CardDefault></template
      ><template v-slot:two>
        <CardDefault
          ><template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Klicks nach Geräte</span
            > </template
          ><template v-slot:main>
            <div class="chart-container">
              <canvas id="clicksByDeviceChart"></canvas>
            </div> </template
        ></CardDefault> </template
      ><template v-slot:three>
        <CardDefault>
          <template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Klicks nach Sprache</span
            >
          </template>
          <template v-slot:main>
            <div class="chart-container">
              <canvas id="clicksByLanguageChart"></canvas>
            </div>
          </template> </CardDefault></template
      ><template v-slot:four>
        <CardDefault>
          <template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Klicks nach Land</span
            >
          </template>
          <template v-slot:main>
            <div class="chart-container">
              <canvas id="clicksByLanguageChart2"></canvas>
            </div>
          </template>
        </CardDefault> </template
    ></GridCols4>
    <GridCols2>
      <template v-slot:left>
        <CardDefault>
          <template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Klicks nach Hersteller</span
            >
          </template>
          <template v-slot:main>
            <div class="chart-container">
              <canvas id="clicksByBrandChart"></canvas>
            </div>
          </template>
        </CardDefault>
      </template>
      <template v-slot:right>
        <CardDefault>
          <template v-slot:header-left>
            <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
              >Klicks nach Betriebssystem</span
            ></template
          >
          <template v-slot:main>
            <div class="chart-container">
              <canvas id="clicksByOSChart"></canvas>
            </div>
          </template>
        </CardDefault>
      </template>
    </GridCols2>
        <!-- Neue Card für Klicks nach Ländern -->
        <CardDefault class="my-5">
      <template v-slot:header-left>
        <span :class="isDarkMode ? 'text-white' : 'text-gray-400'">
          Klicks nach Land
        </span>
      </template>
      <template v-slot:main>
        <div class="chart-container">
          <canvas id="clicksByCountryChart" class="max-h-72"></canvas>
        </div>
      </template>
    </CardDefault>
    <CardDefault class="my-5"
      ><template v-slot:header-left>
        <span :class="isDarkMode ? 'text-white' : 'text-gray-400'"
          >Klicks nach Referrer</span
        > </template
      ><template v-slot:main>
        <div class="chart-container">
          <canvas
            class="max-h-72"
            id="clicksByReferrerChart"
          ></canvas></div></template
    ></CardDefault>
  </div>
  <div class="flex justify-between pt-5 pb-10">
    <div></div>
    <div class="flex gap-2">
      <div></div>
      <ButtonDefaultColor>Statistik zurücksetzen</ButtonDefaultColor>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { apiClient } from "@/components/config/api.js";
import CardDefault from "@/components/reusable/CardDefault.vue";

export default {
  name: "TrackingCharts",
  components: {
    CardDefault,
  },
  setup() {
    const route = useRoute();
    const shortener_url = ref("");
    const clicksPerDayData = ref({});
    const clicksByDeviceData = ref({});
    const clicksByBrowserData = ref({});
    const clicksByReferrerData = ref({});
    const clicksByOSData = ref({});
    const clicksByBrandData = ref({});
    const clicksByLanguageData = ref({});
    const clickstotalClicks = ref({});
    const clicksByCountryData = ref({}); // Neuer State für Klicks nach Land

    const theme = localStorage.getItem("theme");
    const isDarkMode = theme === "dark";

    const fetchTrackingData = async () => {
      try {
        const response = await apiClient.get(
          `/shorteners/stats/${route.params.id}`
        );
        const data = response.data;

        shortener_url.value = data.shortener_url;
        clickstotalClicks.value = data.totalClicks;
        clicksPerDayData.value = data.clicksPerDay;
        clicksByDeviceData.value = data.clicksByDevice;
        clicksByBrowserData.value = data.clicksByBrowser;
        clicksByReferrerData.value = data.clicksByReferrer;
        clicksByOSData.value = data.clicksByOS;
        clicksByBrandData.value = data.clicksByBrand;
        clicksByLanguageData.value = data.clicksByLanguage;
        clicksByCountryData.value = data.clicksByCountry;

        renderCharts();
      } catch (error) {
        console.error("Fehler beim Laden der Tracking-Daten:", error);
      }
    };

    const renderCharts = () => {
      // Utility function to format a date as 'YYYY-MM-DD'
      function formatDate(date) {
        const d = new Date(date);
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }

      // Ensure that all dates in the range are represented with 0 clicks if missing
      function fillMissingDates(clicksPerDayData) {
        const dateKeys = Object.keys(clicksPerDayData).sort();
        const firstDate = new Date(dateKeys[0]);
        const lastDate = new Date();

        let allDates = [];
        for (
          let dt = new Date(firstDate);
          dt <= lastDate;
          dt.setDate(dt.getDate() + 1)
        ) {
          const formattedDate = formatDate(dt);
          if (!clicksPerDayData[formattedDate]) {
            clicksPerDayData[formattedDate] = 0; // Fill missing dates with 0 clicks
          }
          allDates.push(formattedDate);
        }

        return allDates;
      }

      // Use the function to fill missing dates
      const completeDates = fillMissingDates(clicksPerDayData.value);

      new Chart(document.getElementById("clicksPerDayChart"), {
        type: "line",
        data: {
          labels: completeDates,
          datasets: [
            {
              label: "Klicks pro Tag",
              data: completeDates.map((date) => clicksPerDayData.value[date]),
              backgroundColor: "rgba(255, 0, 215, 0.3)",
              borderColor: "#FF00D7",
              borderWidth: 2,
              fill: true,
              tension: 0.4,
              pointRadius: 3,
              pointHoverRadius: 5,
              pointBackgroundColor: "#FF00D7",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += `${context.raw} Klicks`;
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 0,
                color: isDarkMode ? "#FFFFFF" : "#000000",
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                color: "rgba(200, 200, 200, 0.2)",
              },
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000",
              },
            },
          },
        },
      });

      // Klicks nach Gerät
      new Chart(document.getElementById("clicksByDeviceChart"), {
        type: "doughnut",
        data: {
          labels: Object.keys(clicksByDeviceData.value),
          datasets: [
            {
              data: Object.values(clicksByDeviceData.value),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56"],
              hoverBackgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56"],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "70%", // Macht den Doughnut dünner
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const value = tooltipItem.raw;
                  return `Anzahl: ${value}`;
                },
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                let percentage = ((value / sum) * 100).toFixed(2) + "%";
                return percentage;
              },
              color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              font: {
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      });

      // Klicks nach Browser
      new Chart(document.getElementById("clicksByBrowserChart"), {
        type: "doughnut",
        data: {
          labels: Object.keys(clicksByBrowserData.value),
          datasets: [
            {
              data: Object.values(clicksByBrowserData.value),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56", "#4BC0C0"],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "70%", // Macht den Doughnut dünner
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const value = tooltipItem.raw;
                  return `Anzahl: ${value}`;
                },
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                let percentage = ((value / sum) * 100).toFixed(2) + "%";
                return percentage;
              },
              color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              font: {
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      });

      // Klicks nach Referrer
      new Chart(document.getElementById("clicksByReferrerChart"), {
        type: "bar",
        data: {
          labels: Object.keys(clicksByReferrerData.value),
          datasets: [
            {
              label: "Klicks nach Referrer",
              data: Object.values(clicksByReferrerData.value),
              backgroundColor: "rgba(255, 0, 215, 0.3)",
              borderColor: "#FF00D7",
              borderWidth: 2,
              borderRadius: 10,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "70%", // Macht den Doughnut dünner
          scales: {
            x: {
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              },
            },
          },
        },
      });

      // Klicks nach Betriebssystem
      new Chart(document.getElementById("clicksByOSChart"), {
        type: "bar",
        data: {
          labels: Object.keys(clicksByOSData.value),
          datasets: [
            {
              label: "Klicks nach Betriebssystem",
              data: Object.values(clicksByOSData.value),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56", "#4BC0C0"],
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              },
            },
          },
        },
      });

      // Klicks nach Hersteller
      new Chart(document.getElementById("clicksByBrandChart"), {
        type: "bar",
        data: {
          labels: Object.keys(clicksByBrandData.value),
          datasets: [
            {
              label: "Klicks nach Hersteller",
              data: Object.values(clicksByBrandData.value),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56", "#4BC0C0"],
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      // Zusammenfassung der Sprachdaten nach Länderkürzel, ignoriert alles nach dem Semikolon
      function aggregateLanguageData(languageData) {
        const aggregatedData = {};

        for (const [key, value] of Object.entries(languageData)) {
          // Nimmt den Teil des Sprachcodes vor dem Semikolon
          const lang = key.split(";")[0].split(",")[0].toUpperCase();
          if (aggregatedData[lang]) {
            aggregatedData[lang] += value;
          } else {
            aggregatedData[lang] = value;
          }
        }

        return aggregatedData;
      }

      // Aggregierte Daten für das Chart verwenden
      const aggregatedLanguageData = aggregateLanguageData(
        clicksByLanguageData.value
      );

      new Chart(document.getElementById("clicksByLanguageChart"), {
        type: "doughnut",
        data: {
          labels: Object.keys(aggregatedLanguageData), // Länderkürzel ohne Duplikate
          datasets: [
            {
              data: Object.values(aggregatedLanguageData),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56", "#4BC0C0"],
              hoverBackgroundColor: [
                "#FF00D7",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "70%", // Macht den Doughnut dünner
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const label = tooltipItem.label || "";
                  const value = tooltipItem.raw;
                  return `${label}: ${value} Klicks`;
                },
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                let percentage = ((value / sum) * 100).toFixed(2) + "%";
                return percentage;
              },
              color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              font: {
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      });

      new Chart(document.getElementById("clicksByLanguageChart2"), {
        type: "doughnut",
        data: {
          labels: Object.keys(aggregatedLanguageData), // Länderkürzel ohne Duplikate
          datasets: [
            {
              data: Object.values(aggregatedLanguageData),
              backgroundColor: ["#FF00D7", "#36A2EB", "#FFCE56", "#4BC0C0"],
              hoverBackgroundColor: [
                "#FF00D7",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "70%", // Macht den Doughnut dünner
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const label = tooltipItem.label || "";
                  const value = tooltipItem.raw;
                  return `${label}: ${value} Klicks`;
                },
              },
            },
            datalabels: {
              formatter: (value, ctx) => {
                let sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                let percentage = ((value / sum) * 100).toFixed(2) + "%";
                return percentage;
              },
              color: isDarkMode ? "#FFFFFF" : "#000000", // Dynamische Farbe basierend auf dem Dark Mode
              font: {
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      });

      new Chart(document.getElementById("clicksByCountryChart"), {
        type: "bar",
        data: {
          labels: Object.keys(clicksByCountryData.value), // Länderlabels
          datasets: [
            {
              label: "Klicks nach Land",
              data: Object.values(clicksByCountryData.value), // Anzahl der Klicks nach Land
              backgroundColor: "rgba(255, 0, 215, 0.3)",
              borderColor: "#FF00D7",
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            x: {
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000",
              },
            },
            y: {
              beginAtZero: true,
              ticks: {
                color: isDarkMode ? "#FFFFFF" : "#000000",
              },
            },
          },
        },
      });
    };

    onMounted(() => {
      fetchTrackingData();
    });

    return {
      shortener_url,
      isDarkMode,
      clickstotalClicks,
    };
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  margin: 5px auto;
}

canvas {
  max-width: 100%;
}
</style>
