<template>
  <div>
    <ModelDefault ref="childRef">
      <template v-slot:HeaderText>QR-Code downloaden</template>
    </ModelDefault>
  </div>
</template>

<script>
import ModelDefault from "@/components/reusable/Modal.vue";

export default {
  name: "ModalQrCodeDownload",
  components: {
    ModelDefault,
  },
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {
    openModal() {
      this.$refs.childRef.openModal();
    },
    closeModal() {
      this.$refs.childRef.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped></style>
