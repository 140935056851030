<template>
  <div
    :id="`popup-modal-${id}`"
    tabindex="-1"
    class="fixed inset-0 z-50 overflow-y-auto overflow-x-hidden h-full w-full"
    @click.self="closeModal"
  >
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="flex items-center justify-center min-h-full p-4 text-center">
      <div
        class="relative w-full max-w-md p-4 rounded-lg shadow bg-secondary-light dark:bg-secondary-dark"
      >
        <div class="flex justify-end">
          <button
            @click="closeModal"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            aria-label="close"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div class="p-4 md:p-5 text-center">
          <svg
            class="mx-auto mb-4 w-12 h-12 text-gray-400 dark:text-gray-200"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Sind Sie sicher, dass Sie dieses Element löschen möchten?
          </h3>
          <button
            @click="confirmDelete"
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
          >
            Ja, ich bin sicher
          </button>
          <button
            @click="closeModal"
            type="button"
            class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          >
            Nein, abbrechen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalDeleteBanner",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("close");
      document.removeEventListener("keydown", this.handleEsc);
    },
    confirmDelete() {
      this.$emit("confirm", this.id);
      this.closeModal();
    },
    handleEsc(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        this.closeModal();
      }
    },
  },
  mounted() {
    // Registrieren des ESC-Listeners, sobald das Modal eingehängt wird
    document.addEventListener("keydown", this.handleEsc);
  },
  beforeUnmount() {
    // Entfernen des Listeners, bevor die Komponente zerstört wird
    document.removeEventListener("keydown", this.handleEsc);
  },
};
</script>
<style scoped>
/* Ihr spezifischer Stil hier */
</style>
