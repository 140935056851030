import { createRouter, createWebHistory } from 'vue-router';
import PublicLayout from '../views/public/PublicLayout.vue';
import Home from '../views/public/Home.vue';
import About from '../views/public/About.vue';
import Projects from '../views/public/Projects.vue';
import SingleProject from '../views/public/SingleProject.vue';
import Contact from '../views/public/Contact.vue';
import NotFound from '../views/public/NotFound.vue';
import AuthLayout from '../views/auth/AuthLayout.vue'; // Neues Layout für den Auth-Bereich
import Dashboard from '../views/auth/Dashboard.vue'; // Geschützte Dashboard-Seite
import Links from '../views/auth/Links.vue'; // Geschützte Dashboard-Seite
import Add from '../views/auth/Add.vue'; // Geschützte Dashboard-Seite
import AddShortUrl from '../views/auth/AddShortUrl.vue'; // Geschützte Dashboard-Seite
import Pricing from '../views/auth/Pricing.vue'; // Geschützte Dashboard-Seite
import ShortenerDetails from '../views/auth/ShortenerDetails.vue'; // Geschützte Dashboard-Seite
import Test from '../views/auth/Test.vue'; // Geschützte Dashboard-Seite
import Profil from '../views/auth/Profil.vue'; // Geschützte Dashboard-Seite
import Login from '../views/public/Login.vue'; // Login-Seite
import Register from '../views/public/Register.vue'; // Login-Seite
import Logout from '../views/public/Logout.vue'; // Logout-Seite
import PasswordRequest from '../views/public/PasswordRequest.vue'; // Passwort-Anforderungs-Seite
import i18n from '../i18n';
import { apiClient } from "@/components/config/api.js";

const routes = [
	{
		path: '/:lang(en|de|nl)?',
		children: [
			{
				path: 'auth',
				name: 'User Area',
				component: AuthLayout,
				children: [
					{
						path: 'dashboard',
						name: 'Dashboard',
						component: Dashboard,
						meta: { requiresAuth: true },
					},
					{
						path: 'links',
						name: 'Links',
						component: Links,
						meta: { requiresAuth: true },
					},
					{
						path: 'add',
						name: 'Add',
						component: Add,
						meta: { requiresAuth: true },
					},
					{
						path: 'add/addshorturl',
						name: 'addShortUrl',
						component: AddShortUrl,
						meta: { requiresAuth: true },
					},
					{
						path: 'links/:id',
						name: 'ShortenerDetails',
						component: ShortenerDetails,
						meta: { requiresAuth: true },
					},
					{
						path: 'profil',
						name: 'Profil',
						component: Profil,
						meta: { requiresAuth: true },
					},
					{
						path: 'pricing',
						name: 'Pricing',
						component: Pricing,
						meta: { requiresAuth: true },
					},
					{
						path: 'test',
						name: 'Test',
						component: Test,
					},
				],
			},
			{
				path: '',
				name: 'Public',
				component: PublicLayout,
				children: [
					{
						path: '',
						name: 'Home',
						component: Home,
						meta: { title: 'QrLine.io - Home' },
					},
					{
						path: 'about',
						name: 'About',
						component: About,
						meta: { title: 'QrLine.io - About' },
					},
					{
						path: 'projects',
						name: 'Projects',
						component: Projects,
						meta: { title: 'QrLine.io - Projects' },
					},
					{
						path: 'projects/single-project',
						name: 'Single Project',
						component: SingleProject,
						meta: { title: 'QrLine.io - Single Project' },
					},
					{
						path: 'contact',
						name: 'Contact',
						component: Contact,
						meta: { title: 'QrLine.io - Contact' },
					},
					{
						path: 'register',
						name: 'Register',
						component: Register,
						meta: { title: 'QrLine.io - Register' },
					},
					{
						path: 'login',
						name: 'Login',
						component: Login,
						meta: { title: 'QrLine.io - Login' },
					},
					{
						path: 'logout',
						name: 'Logout',
						component: Logout,
					},
					{
						path: 'password/request',
						name: 'PasswordRequest',
						component: PasswordRequest,
					},
				],
			},
		],
	},
	{ path: '/:catchAll(.*)', name: 'NotFound', component: NotFound },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	}
});

router.beforeEach(async (to, from, next) => {
	let lang = to.params.lang;

	if (!lang) {
		lang = localStorage.getItem('lang') || navigator.language.split('-')[0] || 'de';
		// Falls die URL keine Sprache enthält, aber eine Sprache im localStorage gespeichert ist,
		// oder die Sprache des Browsers verwendet werden soll, dann leite auf den entsprechenden Pfad weiter
		if (!to.path.startsWith(`/${lang}`)) {
			return next({ path: `/${lang}${to.path}` });
		}
	}

	i18n.global.locale = lang;
	localStorage.setItem('lang', lang);
	document.title = to.meta.title || 'QrLine.io';

	const isPublicRoute = [`/${lang}/login`, `/${lang}/register`, `/${lang}/password/request`].includes(to.path);

	if (to.meta.requiresAuth) {
		try {
			const response = await apiClient.get('/check-session', { withCredentials: true });
			if (response.data.authenticated) {
				if (isPublicRoute) {
					// Wenn der Benutzer authentifiziert ist und auf eine öffentliche Route zugreifen möchte, leite ihn um
					next({ path: `/${lang}/auth/dashboard` }); // Oder eine andere geschützte Seite, auf die umgeleitet werden soll
				} else {
					next();
				}
			} else {
				next({ path: `/${lang}/login` });
			}
		} catch (error) {
			console.error('Error during authentication check:', error);
			next({ path: `/${lang}/login` });
		}
	} else if (isPublicRoute) {
		try {
			const response = await apiClient.get('/check-session', { withCredentials: true });
			if (response.data.authenticated) {
				// Wenn der Benutzer authentifiziert ist und versucht, eine öffentliche Route zuzugreifen
				next({ path: `/${lang}/auth/dashboard` }); // Oder eine andere geschützte Seite
			} else {
				next();
			}
		} catch (error) {
			console.error('Error during authentication check:', error);
			next();
		}
	} else {
		next();
	}
});


export default router;
