import Cookies from "js-cookie";
import axios from "axios";

// Definieren Sie die URL der API, von der wir Daten abrufen möchten
export const apiUrl = process.env.VUE_APP_API_URL;

// Definieren Sie die Header, um das Token an die API zu senden
export const authToken = Cookies.get("token");
export const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${authToken}`,
};
export const headersupload = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${authToken}`,
};
// Erstellen und konfigurieren Sie die Axios-Instanzen
const createApiClient = (contentType) => {
  const apiClient = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
      "Content-Type": contentType,
    },
  });

  return apiClient;
};

export const apiClient = createApiClient("application/json");
export const apiClientData = createApiClient("multipart/form-data");
export const apiClientAll = createApiClient("application/json");
