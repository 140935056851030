<script>
import CardDefault from "@/components/reusable/CardDefault.vue";
import FormInput from "@/components/reusable/FormInput.vue";

export default {
  name: "PasswordRequest",
  components: {
    CardDefault,
    FormInput,
  },
  data: () => {
    return {};
  },
  mounted() {},
  updated() {},
  methods: {},
};
</script>

<template>
  <div class="flex flex-col items-center justify-center md:mt-24">
    <CardDefault class="max-w-xl"
      ><template v-slot:header-left>Passwort zurücksetzen</template
      ><template v-slot:main>
        <div class="p-2">
          <FormInput class="py-1" label="Mail Adresse" inputIdentifier="mail" />
          <ButtonDefaultColor class="w-full mt-5">Passwort zurücksetzen</ButtonDefaultColor>
          <router-link to="/login">
            <p
              class="font-general-regular text-left text-secondary-dark dark:text-ternary-light hover:text-pink-light dark:hover:text-pink-light text-md font-normal my-4"
            >
              Zurück
            </p>
          </router-link>
        </div></template
      ></CardDefault
    >
  </div>
</template>

<style scoped></style>
