<script>
export default {
  props: {
    label: {
      type: String,
      default: "Username",
    },
    inputIdentifier: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    inputType: {
      type: String,
      default: "text",
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
};
</script>

<template>
  <div>
    <div class="relative w-full min-w-[200px] h-10 my-5">
      <input
        :id="inputIdentifier"
        :name="inputIdentifier"
        :value="modelValue"
        :type="inputType"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
		class="peer w-full h-full bg-transparent dark:text-white text-black font-sans font-normal outline-none focus:outline-none focus:border-t-0 focus:border-l-0 focus:border-r-0 focus:ring-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border border-t-transparent border-l-transparent border-r-transparent text-sm px-3 py-5"
        placeholder=" "
      />
      <label
        :for="inputIdentifier"
        class="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-gray-400 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-gray-400 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-400 peer-focus:text-gray-400 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900"
      >
        {{ label }}
      </label>
      <p v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</p>
    </div>
  </div>
</template>

<style scoped>
/* Hier können zusätzliche Anpassungen hinzugefügt werden, falls benötigt */
</style>
