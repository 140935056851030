<script>
import { apiClient } from "@/components/config/api.js";
import ActivlistTable from "@/components/shared/auth/ActivlistTable.vue";
import ActivlistCard from "@/components/shared/auth/ActivlistCard.vue";
import Pagination from "@/components/reusable/Pagination.vue";

export default {
  name: "Links",
  components: {
    ActivlistTable,
    ActivlistCard,
    Pagination,
  },
  data() {
    return {
      items: [],
      totalPages: 1,
      totalRecords: 0,
      page: this.$route.query.page || 1,
      recordsPerPage: this.$route.query.perPage || 10,
      sortField: this.$route.query.sortField || "shortener_url", // Standard-Sortierfeld
      sortOrder: this.$route.query.sortOrder || "asc", // Standard-Sortierreihenfolge
      search: {
        shortener_url: this.$route.query.shortener_url || "",
        name: this.$route.query.name || "",
        createdAt: this.$route.query.createdAt || "",
      },
      selectedFilterField: this.$route.query.selectedFilterField || "name", // Default filter field
      searchFilterValue: this.$route.query.searchFilterValue || "", // Value for the selected filter field
      loading: true,
    };
  },
  methods: {
    fetchSortedData(sortField = this.sortField, sortOrder = this.sortOrder) {
      this.sortField = sortField;
      this.sortOrder = sortOrder;

      apiClient
        .get("shorteners", {
          params: {
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            ...this.search,
            page: this.page,
            perPage: this.recordsPerPage,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
          this.updateUrl();
          this.loading = false;
        })
        .catch((error) => {
          console.error("Fehler beim Abrufen der Shorteners:", error);
          this.loading = false;
        });
    },
    updateUrl() {
      this.$router.push({
        query: {
          ...this.search,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          page: this.page,
          perPage: this.recordsPerPage,
          selectedFilterField: this.selectedFilterField,
          searchFilterValue: this.searchFilterValue,
        },
      });
    },
    applyFilter() {
      this.loading = true;
      this.search[this.selectedFilterField] = this.searchFilterValue;
      this.page = 1; // Reset to first page
      this.fetchSortedData();
    },
    resetFilters() {
      this.search = {
        shortener_url: "",
        name: "",
        createdAt: "",
      };
      this.sortField = "shortener_url";
      this.sortOrder = "asc";
      this.searchFilterValue = "";
      this.fetchSortedData();
    },
    onPageChange(page) {
      this.page = page;
      this.fetchSortedData();
    },
    onChangeRecordsPerPage() {
      this.page = 1;
      this.fetchSortedData();
    },
    gotoPage() {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno);
        this.fetchSortedData();
      }
    },
    removeDealer(id) {
      this.items.splice(id, 1);
      //this.$delete(this.dealers, id);
    },
  },
  mounted() {
    this.fetchSortedData();
  },
  watch: {
    search: {
      handler() {
        this.fetchSortedData();
      },
      deep: true,
    },
    sortField() {
      this.updateUrl();
    },
    sortOrder() {
      this.updateUrl();
    },
    page() {
      this.updateUrl();
    },
    recordsPerPage() {
      this.updateUrl();
    },
  },
};
</script>

<template>
  <div class="mb-10">
    <div class="flex flex-col md:flex-row justify-between mb-5">
  <!-- Buttons -->
  <div class="flex flex-col gap-2 mb-4 md:mb-0 md:flex-row">
    <router-link to="/auth/add">
    <ButtonDefaultColor  class="w-full">Add Short</ButtonDefaultColor></router-link>
    <ButtonDefaultColor @click="resetFilters">Filter zurücksetzen</ButtonDefaultColor>
  </div>

  <!-- Search filter (immer auf einer Linie) -->
  <div class="flex items-center space-x-0">
    <!-- Dropdown for selecting filter field -->
    <select
      v-model="selectedFilterField"
      @change="resetFilters"
      class="block w-40 px-4 py-2.5 text-base text-gray-900 border border-gray-300 rounded-l-lg bg-gray-50 dark:bg-secondary-dark dark:border-none dark:text-white"
    >
      <option value="name">Name</option>
      <option value="shortener_url">URL</option>
    </select>

    <!-- Input for filter value -->
    <input
      v-model="searchFilterValue"
      type="text"
      placeholder="Filter value"
      class="px-4 py-2.5 border border-gray-300 dark:border-none dark:bg-secondary-dark dark:text-white w-full"
    />

    <!-- Search button -->
    <button
      type="button"
      @click="applyFilter"
      class="text-md font-general-medium border-2 bg-pink-light border-pink-light dark:border-none hover:border-pink-dark hover:bg-pink-dark text-white shadow-sm rounded-r-lg px-5 py-2.5 duration-300"
    >
      <i class="bi bi-search"></i>
    </button>
  </div>
</div>


    <div>
      <div v-if="loading"><SpinnerColor></SpinnerColor></div>
      <div v-else>
        <!-- ActivlistTable: Sichtbar nur auf kleineren Bildschirmgrößen bis md -->
        <div class="hidden md:block">
          <ActivlistTable
            :itemsData="items"
            @sort="fetchSortedData"
            @pushdelete="removeDealer"
          ></ActivlistTable>
        </div>

        <!-- ActivlistCard: Sichtbar nur ab Bildschirmgrößen md und größer -->
        <div class="block md:hidden">
          <ActivlistCard
            :itemsData="items"
            @sort="fetchSortedData"
            @pushdelete="removeDealer"
          ></ActivlistCard>
        </div>
      </div>
      <div class="flex justify-between place-items-center pt-5">
        <div></div>
        <div>
          <Pagination
            v-if="items.length"
            :total-pages="totalPages"
            :per-page="recordsPerPage"
            :current-page="page"
            :total-records="totalRecords"
            @pagechanged="onPageChange"
          />
        </div>
        <div>
          <ul class="showItems flex">
            <li>
              <select
                class="block px-4 py-3 w-20 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-secondary-dark dark:border-none dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                @change="onChangeRecordsPerPage"
                v-model.number="recordsPerPage"
              >
                <option :value="2">2</option>
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="40">40</option>
                <option :value="50">50</option>
              </select>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
