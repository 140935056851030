<template>
    <div>
      <label class="inline-flex items-center me-5 cursor-pointer">
        <input
        :id="inputIdentifier"
        :name="inputIdentifier"
        :checked="modelValue"
        type="checkbox"
        @change="$emit('update:modelValue', $event.target.checked)"
        class="sr-only peer"
        />
        <div
          class="relative font-general-regular w-11 h-6 bg-gray-200 rounded-full ring-gray-300 ring-2 peer peer-focus:ring-4 peer-focus:ring-pink-light dark:peer-focus:ring-pink-dark dark:bg-primary-dark peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-pink-dark"
        ></div>
        <span class="ms-3 font-medium text-gray-900 dark:text-gray-300">
          {{ label }}
        </span>
      </label>
      <p v-if="error" class="text-red-500 text-xs mt-1">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: {
        type: String,
        default: "Checkbox Label",
      },
      inputIdentifier: {
        type: String,
        default: "",
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: "",
      },
    },
    emits: ["update:modelValue"],
  };
  </script>
  
  <style scoped>
  /* Add any additional styling if needed */
  </style>
  