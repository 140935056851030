<script>
import { apiClient } from "@/components/config/api.js";
import CardDefault from "@/components/reusable/CardDefault.vue";
import FormInput from "@/components/reusable/FormInput.vue"; // Import FormInput component
import Charts from "@/components/shared/auth/Charts.vue";
import ModalQrCodeDownload from "@/components/shared/auth/ModalQrCodeDownload.vue";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";
import QRCode from "qrcode";

export default {
  name: "ShortenerDetails",
  components: {
    CardDefault,
    FormInput,
    DatePicker,
    Charts,
    ModalQrCodeDownload,
  },
  data() {
    return {
      item: {
        name: "", // Ensure initial value is set
        label: "",
        url: "",
        qrCodeColor: "" || "#000000",
        activ: false,
        date_end: false,
        date_end_time: new Date(),
        date_start: false,
        date_start_time: new Date(),
        statistics_info: false,
        qrcode_color: "",
      },
      date: new Date(),
      loading: true,
      error: "",
      qrCodeData: "",
      qrCodeDataDownload: "",
      logoFile: "", // To hold the base64 or file URL of the uploaded logo
      predefinedColors: ["#000000", "#ff0000", "#007bff", "#ff6600"],
      selectedSize: 150,
    };
  },
  methods: {
    async downloadQRCode() {
      try {
        const link = document.createElement("a");
        await this.generateQRCodeDownload();
        link.href = this.qrCodeDataDownload;
        link.download = `QRCode_${this.selectedSize}x${this.selectedSize}.png`;
        link.click();
      } catch (error) {
        console.error("Fehler beim Erzeugen des QR-Codes:", error);
      }
    },
    async generateQRCodeDownload() {
      try {
        this.qrCodeDataDownload = await QRCode.toDataURL(
          "https://link.kfzdealer.com/" + this.item.shortener_url,
          {
            width: this.selectedSize,
            margin: 4,
            color: {
              dark: this.item.qrcode_color,
              light: "#ffffff",
            },
          }
        );
      } catch (error) {
        console.error("Fehler beim Erzeugen des QR-Codes:", error);
      }
    },
    openModal() {
      this.$refs.childRef.openModal();
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.open({
            message: "URL wurde erfolgreich in die Zwischenablage kopiert!",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((err) => {
          console.error("Fehler beim Kopieren in die Zwischenablage:", err);
        });
    },
    selectColor(color) {
      this.item.qrcode_color = color;
      this.generateQRCode();
      this.save();
    },
    indColor() {
      this.generateQRCode();
      this.save();
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.uploadLogo(file);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.uploadLogo(file);
    },
    uploadLogo(file) {
      if (file && file.type === "image/png") {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoFile = e.target.result; // Set the logo to be displayed
          this.item.logo = file; // Store the file for further processing (e.g., uploading to server)
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast.open({
          message: "Bitte nur PNG-Dateien hochladen.",
          type: "error",
          position: "bottom-right",
        });
      }
    },
    async fetchItem() {
      try {
        const response = await apiClient.get(
          "shorteners/" + this.$route.params.id
        );
        this.item = response.data;
        if (this.item.logo) {
          this.logoFile = this.item.logo; // If there's a stored logo, display it
        }
        await this.generateQRCode();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;

      // Überprüfung, ob die URL mit "https://" beginnt
      if (!this.item.url.startsWith("https://")) {
        this.$toast.open({
          message: "Die URL muss mit https:// beginnen.",
          type: "error",
          position: "bottom-right",
        });
        this.loading = false;
        return;
      }

      // Erstelle die Daten ohne den Logo-Upload
      const data = {
        name: this.item.name,
        label: this.item.label,
        url: this.item.url,
        activ: this.item.activ,
        date_end: this.item.date_end,
        date_end_time: this.item.date_end_time,
        date_start: this.item.date_start,
        date_start_time: this.item.date_start_time,
        statistics_info: this.item.statistics_info,
        qrcode_color: this.item.qrcode_color,
      };

      try {
        const response = await apiClient.put(
          "shorteners/" + this.$route.params.id,
          data
        );
        this.$toast.open({
          message: response.data.message,
          type: "success",
          position: "bottom-right",
        });
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Fehler beim Speichern des Links",
          type: "error",
          position: "bottom-right",
        });
      } finally {
        this.loading = false;
      }
    },
    async generateQRCode() {
      try {
        this.qrCodeData = await QRCode.toDataURL(
          "https://link.kfzdealer.com/" + this.item.shortener_url,
          {
            width: 200,
            margin: 4,
            color: {
              dark: this.item.qrcode_color,
              light: "#ffffff",
            },
          }
        );
      } catch (error) {
        console.error("Fehler beim Erzeugen des QR-Codes:", error);
      }
    },
  },
  async created() {
    try {
      this.fetchItem();
    } catch (error) {
      console.error("Fehler beim Erzeugen des QR-Codes:", error);
    }
  },
};
</script>

<template>
  <div>
    <GridCols2>
      <template v-slot:left>
        <CardDefault>
          <template v-slot:header-left>Allgemein</template>
          <template v-slot:header-right>
            <div class="flex">
              <FormCheckbox
                v-model="item.activ"
                inputIdentifier="terms"
                :label="item.activ ? 'Aktiv' : 'Pausiert'"
                :error="error"
              /></div
          ></template>
          <template v-slot:main>
            <FormInput
              class="py-1"
              label="Name"
              inputType="text"
              v-model="item.name"
              :inputIdentifier="'name'"
              :error="passwordError"
            />
            <FormInput
              class="py-1"
              label="Beschreibung"
              inputType="text"
              v-model="item.label"
              :inputIdentifier="'label'"
              :error="passwordError"
            />
            <FormInput
              class="py-1"
              label="Original URL"
              inputType="text"
              v-model="item.url"
              :inputIdentifier="'url'"
              :error="passwordError"
            />
            <FormCheckbox
              v-model="item.statistics_info"
              inputIdentifier="terms"
              label="Statistik aktivieren"
              :error="error"
            />
            <div v-if="item.statistics_info == !false">
              <FormCheckbox
                v-model="item.einwilligung"
                inputIdentifier="terms"
                label="Datenschutz Einwilligung aktivieren"
                :error="error"
              />
            </div>
            <div class="lg:flex md:justify-between">
              <div class="my-2">
                <FormCheckbox
                  v-model="item.date_start"
                  inputIdentifier="terms"
                  label="Start des Links"
                  :error="error"
                />
              </div>
              <div v-if="item.date_start">
                <DatePicker v-model="item.date_start_time" :popover="false">
                  <template
                    #default="{ togglePopover, inputValue, inputEvents }"
                  >
                    <div
                      class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden"
                    >
                      <button
                        class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
                        @click="() => togglePopover()"
                      >
                        <i
                          class="bi bi-calendar-date text-lg dark:text-white"
                        ></i>
                      </button>
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="flex-grow px-2 py-1 bg-white dark:bg-primary-dark dark:text-white border-none"
                      />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="lg:flex md:justify-between">
              <div class="my-2">
                <FormCheckbox
                  v-model="item.date_end"
                  inputIdentifier="terms"
                  label="Ablauf des Links"
                  :error="error"
                />
              </div>
              <div v-if="item.date_end">
                <DatePicker v-model="item.date_end_time" :popover="false">
                  <template
                    #default="{ togglePopover, inputValue, inputEvents }"
                  >
                    <div
                      class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden"
                    >
                      <button
                        class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
                        @click="() => togglePopover()"
                      >
                        <i
                          class="bi bi-calendar-date text-lg dark:text-white"
                        ></i>
                      </button>
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="flex-grow px-2 py-1 bg-white dark:bg-primary-dark dark:text-white border-none"
                      />
                    </div>
                  </template>
                </DatePicker>
              </div>
            </div>
            <div class="mt-5 flex">
              <ButtonDefaultColor @click="save">speichern</ButtonDefaultColor>
            </div>
          </template>
        </CardDefault>
      </template>
      <template v-slot:right>
        <CardDefault>
          <template v-slot:header-left>Link</template>
          <template v-slot:header-right> </template>
          <template v-slot:main>
            <div class="py-1 flex items-center justify-between dark:text-white">
              <span
                class="w-full p-5 rounded-lg dark:bg-primary-dark bg-primary-light cursor-pointer"
                @click="
                  copyToClipboard(
                    `https://link.kfzdealer.com/${item.shortener_url}`
                  )
                "
                ><div>
                  <p>https://link.kfzdealer.com/{{ item.shortener_url }}</p>
                </div></span
              >
              <div></div>
            </div>
            <button
              type="button"
              @click="
                copyToClipboard(
                  `https://link.kfzdealer.com/${item.shortener_url}`
                )
              "
            >
              <div class="flex gap-2 mt-5 dark:text-white">
                <i class="bi bi-copy text-lg"></i>
                <p class="">Link kopieren</p>
              </div>
            </button>
          </template>
        </CardDefault>
        <CardDefault class="mt-5">
          <template v-slot:header-left>QR-Code</template>
          <template v-slot:header-right> </template>
          <template v-slot:main>
            <div>
              <div class="flex justify-between gap-5">
                <div v-if="loading">
                  <div class="flex justify-center items-center">
                    <SpinnerWhiteSmall></SpinnerWhiteSmall>
                  </div>
                </div>
                <div v-else>
                  <div @click="openModal()">
                    <img :src="qrCodeData" alt="QR Code" class="rounded-lg" />
                  </div>
                </div>
                <div class="">
                  <!-- Benutzerdefinierte Farbauswahl -->
                  <div class="">
                    <span class="text-gray-500 dark:text-gray-300"
                      >Benutzerdefinierte Farbe</span
                    >
                    <div class="position-relative">
                      <!-- Farbwähler als ausgefüllter Kreis -->
                      <div class="flex items-center mb-6">
                        <input
                          type="color"
                          v-model="item.qrcode_color"
                          @change="indColor"
                          class="w-10 h-10 p-0 border-0 rounded-full cursor-pointer"
                          :style="{ backgroundColor: item.qrCodeColor }"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Vordefinierte Farben -->
                  <div class="items-center">
                    <p class="block text-gray-700 dark:text-gray-300">
                      Oder vordefinierte Farben:
                    </p>
                    <button
                      v-for="color in predefinedColors"
                      :key="color"
                      :style="{ backgroundColor: color }"
                      @click="selectColor(color)"
                      class="w-10 h-10 rounded-full border-2 cursor-pointer mr-2"
                      :class="
                        qrCodeColor === color
                          ? 'border-pink-500'
                          : 'border-gray-300'
                      "
                    ></button>
                  </div>
                </div>
              </div>
              <div class="flex justify-between mt-5">
                <div></div>
                <div class="flex items-center space-x-0">
                  <!-- Dropdown for selecting filter field -->
                  <select
                    v-model="selectedSize"
                    @change="generateQRCode"
                    class="block w-36 px-4 py-3 text-base text-gray-900 border border-gray-300 rounded-l-lg bg-gray-50 dark:bg-secondary-dark dark:border-1 dark:text-white"
                  >
                    <option :value="50">50x50</option>
                    <option :value="100">100x100</option>
                    <option :value="150">150x150</option>
                    <option :value="200">200x200</option>
                    <option :value="400">400x400</option>
                    <option :value="500">500x500</option>
                    <option :value="800">800x800</option>
                    <option :value="1200">1200x1200</option>
                    <option :value="1800">1800x1800</option>
                  </select>

                  <!-- Search button -->
                  <button
                    type="button"
                    @click="downloadQRCode"
                    class="text-md font-general-medium border-0 bg-pink-light border-pink-light dark:border-none hover:border-pink-dark hover:bg-pink-dark text-white shadow-sm rounded-r-lg px-5 py-2.5 duration-300"
                  >
                    <i class="bi bi-arrow-down-circle text-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </CardDefault>
      </template>
    </GridCols2>
    <div v-if="item.statistics_info">
      <Charts></Charts>
    </div>
    <ModalQrCodeDownload ref="childRef"></ModalQrCodeDownload>
  </div>
</template>

<style scoped></style>
