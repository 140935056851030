<script>
export default {};
</script>

<template>
  <div
    class="leading-loose w-full h-full p-7 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl text-left"
  >
    <div class="flex justify-between">
      <p
        class="font-general-medium text-primary-dark dark:text-primary-light text-2xl mb-8"
      >
        <slot name="header-left"></slot>
      </p>
      <p
        class="font-general-medium text-primary-dark dark:text-primary-light text-2xl mb-8"
      >
        <slot name="header-right"></slot>
      </p>
    </div>
    <slot name="main"> </slot>
    <div class="absolute bottom-10"><slot name="footer"> </slot></div>
  </div>
</template>

<style lang="scss" scoped></style>
