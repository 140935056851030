<template>
  <div class="language-selector">
    <select
      @change="changeLanguage"
      :value="currentLang"
      class="select-language dark:text-white"
    >
      <option v-for="(label, code) in languages" :key="code" :value="code">
        {{ label }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const route = useRoute();

    // Synchronisiere currentLang mit locale
    const currentLang = ref(locale.value);

    watch(locale, (newLocale) => {
      currentLang.value = newLocale;
    });

    const languages = {
      en: "English",
      de: "Deutsch",
      fr: "Français",
      nl: "Nederlands",
      es: "Español",
      it: "Italiano",
      pl: "Polski",
      cz: "Čeština",
      tr: "Türkçe",
    };

    function changeLanguage(event) {
      const selectedLang = event.target.value;
      locale.value = selectedLang;
      localStorage.setItem("lang", selectedLang);

      // Update the URL by replacing the language segment
      const newPath = `/${selectedLang}${route.path.replace(
        /^\/(en|de|fr|nl|es|it|pl|cz|tr)/,
        ""
      )}`;
      router.push(newPath);
    }

    return { languages, currentLang, changeLanguage };
  },
};
</script>

<style scoped>
.language-selector {
  display: flex;
  align-items: center;
  margin: 10px;
}

.select-language {
  background-color: var(--background);
  color: var(--text);
  border: 1px solid var(--border);
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 18px;
  cursor: pointer;
  appearance: none;
}

.select-language option {
  display: flex;
  align-items: center;
}

.flag-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

/* Dark Mode styles */
.dark-mode .select-language {
  background-color: var(--background-dark);
  color: var(--text-dark);
  border-color: var(--border-dark);
}

/* Light Mode styles */
.light-mode .select-language {
  background-color: var(--background-light);
  color: var(--text-light);
  border-color: var(--border-light);
}
</style>
