<template>
  <div class="relative">
    <div class="hidden md:flex gap-3 ml-72">
      <div class="flex justify-center items-center dark:text-white text-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </div>
      <div>
        <input
          type="text"
          placeholder="Type to search..."
          v-model="searchTerm"
          autocomplete="off"
          @input="handleInputSearch"
          @focus="openResults"
          class="w-96 bg-transparent dark:text-white placeholder:dark:text-white focus:outline-none focus:ring-0 border-none"
        />
      </div>
    </div>

    <!-- Suchergebnisse -->
    <div
      v-if="showResults && searchTerm.length >= 3"
      class="absolute w-96 ml-72 bg-white dark:bg-secondary-dark border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg mt-1 z-50"
    >
      <div v-if="item.length > 0">
        <ul
          role="list"
          class="divide-y divide-gray-200 dark:divide-gray-700"
        >
          <li
            class="py-3 sm:py-4 px-4 dark:hover:bg-gray-600 hover:bg-gray-100 hover:rounded-lg"
            v-for="(items, index) in item"
            :key="index"
          >
            <a :href="`/auth/links/${items._id}`">
              <div class="flex items-center space-x-4 justify-end">
                <div class="flex-shrink-0">
                  <div class="flex-1 items-center">
                    <div
                      class="h-4 w-4 rounded-full inline-block mr-2"
                      :class="{
                        'bg-green-500': items.activ == true,
                        'bg-red-500': items.activ == false,
                      }"
                    ></div>
                    <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    {{ items.activ }}
                  </p>
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <p class="text-gray-900 truncate dark:text-white">
                    {{ items.name }}
                  </p>
                  <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    {{ items.label }}
                  </p>
                </div>
                <div class="flex-2 min-w-0">
                  <p
                    class="text-right text-gray-900 truncate dark:text-white"
                  >
                    {{ items.statistics_count }}
                  </p>
                  <p
                    class="text-right text-sm text-gray-500 truncate dark:text-gray-400"
                  >
                    Klicks
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="text-red-500 flex justify-center items-center mt-5" v-else>
        <p class="mb-5">Keine Ergebnisse gefunden.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/components/config/api.js";

export default {
  name: "Search",
  data() {
    return {
      searchTerm: "",
      item: [],
      showResults: false, // Kontrolle, ob Ergebnisse angezeigt werden
    };
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() { // Verwende Vue 3 Lifecycle Hook
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleInputSearch() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.searchTerm.length >= 3) {
          apiClient
            .get(
              `shorteners?sortField=shortener_url&sortOrder=asc&shortener_url=&name=${this.searchTerm}`
            )
            .then((response) => {
              this.item = response.data.data;
              this.showResults = true; // Zeige Ergebnisse
            })
            .catch((error) => {
              this.item = [];
              console.error("Fehler bei der Suche:", error);
            });
        } else {
          this.item = [];
          this.showResults = false; // Keine Ergebnisse anzeigen
        }
      }, 300); // Timeout für Verzögerung der Suche
    },
    openResults() {
      this.showResults = true; // Öffne Ergebnisfenster bei Fokussierung
    },
    closeResults() {
      this.showResults = false; // Schließe Ergebnisfenster
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeResults(); // Ergebnisfenster schließen, wenn außerhalb geklickt wird
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
