import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n.js';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import FormInput from './components/reusable/FormInput.vue'; // Pfad anpassen
import FormCheckbox from './components/reusable/FormCheckbox.vue'; // Pfad anpassen
import ButtonDefaultColor from './components/reusable/ButtonDefaultColor.vue'; // Pfad anpassen
import ButtonDefaultTrans from './components/reusable/ButtonDefaultTrans.vue'; // Pfad anpassen
import SpinnerWhiteSmall from './components/reusable/SpinnerWhiteSmall.vue'; // Pfad anpassen
import SpinnerColor from './components/reusable/SpinnerColor.vue'; // Pfad anpassen
import GridCols2 from './components/reusable/GridCols2.vue'; // Pfad anpassen
import GridCols4 from './components/reusable/GridCols4.vue'; // Pfad anpassen
import Toast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css'; // Importiere die Standard-Toast-Theme-Stile
import 'bootstrap-icons/font/bootstrap-icons.css';

const feather = require('feather-icons');
feather.replace();

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(BackToTop);
app.use(Toast); // Registriere vue-toast-notification

// Registriere deine Komponenten global
app.component('FormInput', FormInput);
app.component('FormCheckbox', FormCheckbox);
app.component('ButtonDefaultColor', ButtonDefaultColor);
app.component('ButtonDefaultTrans', ButtonDefaultTrans);
app.component('SpinnerWhiteSmall', SpinnerWhiteSmall);
app.component('SpinnerColor', SpinnerColor);
app.component('GridCols2', GridCols2);
app.component('GridCols4', GridCols4);

app.mount('#app');

const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
