<template>
  <nav aria-label="Page navigation example">
    <ul class="inline-flex -space-x-px text-base h-10">
      <li>
        <a
          href="#"
          @click.prevent="onClickFirstPage"
          :class="isInFirstPage ? 'disabled' : ''"
          :disabled="isInFirstPage"
          class="flex items-center justify-center hidden md:block pt-3 px-4 h-12 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-secondary-dark dark:hover:bg-ternary-dark dark:border-primary-dark dark:text-white dark:hover:text-white"
          >Erste Seite</a
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="onClickPreviousPage"
          :class="isInFirstPage ? 'disabled' : ''"
          :disabled="isInFirstPage"
          class="flex items-center justify-center px-4 h-12 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-secondary-dark dark:hover:bg-ternary-dark dark:border-primary-dark dark:text-white dark:hover:text-white rounded-s-lg md:rounded-none"
        >
          <span class="sr-only">Previous</span>
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </li>
      <li :key="page.id" v-for="page in pages">
        <a
          href="#"
          @click.prevent="onClickPage(page.name)"
          :disabled="page.isDisabled"
          class="flex items-center justify-center px-4 h-12 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-secondary-dark dark:hover:bg-ternary-dark dark:border-primary-dark dark:text-white dark:hover:text-white"
          >{{ page.name }}</a
        >
      </li>
      <li>
        <a
          href="#"
          @click.prevent="onClickNextPage"
          :class="isInLastPage ? 'disabled' : ''"
          :disabled="isInLastPage"
          class="flex items-center justify-center px-4 h-12 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-secondary-dark dark:hover:bg-ternary-dark dark:border-primary-dark dark:text-white dark:hover:text-white rounded-e-lg md:rounded-none"
        >
          <span class="sr-only">Next</span>
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a>
      </li>
      <li>
        <a
          href="#"
          @click.prevent="onClickLastPage"
          :class="isInLastPage ? 'disabled' : ''"
          :disabled="isInLastPage"
          class="flex items-center justify-center hidden md:block pt-3 px-4 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-secondary-dark dark:hover:bg-ternary-dark dark:border-primary-dark dark:text-white dark:hover:text-white dark:hover:text-white"
          >Letzte Seite ({{ totalPages }})</a
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: [String, Number, Array, Object],
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalRecords: {
      type: Number,
    },
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.totalPages === 1) {
        return true;
      }
      return this.currentPage === this.totalPages;
    },
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }
      // When on the last page
      if (this.totalPages < this.maxVisibleButtons) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      // When in between
      return this.currentPage - 1;
    },
    endPage() {
      if (this.totalPages === 0) {
        return 0;
      }
      if (this.totalPages < this.maxVisibleButtons) {
        return this.totalPages;
      }
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
  },
  methods: {
    onClickFirstPage() {
      if (this.isInFirstPage) {
        return false;
      }
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      if (this.isInFirstPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      if (this.isInLastPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      console.log("onClickLastPage");
      if (this.isInLastPage) {
        return false;
      }
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
