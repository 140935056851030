<template>
  <div class="relative flex justify-center items-center">
    <!-- Profilbild -->
    <div class="pill-full border-2 border-pink-light rounded-full w-10 cursor-pointer" @click="toggleDropdown">
      <img src="https://p.kfzdealer.com/img/user-01-OMqtv7h9.png" alt="Profilbild" />
    </div>

    <!-- Dropdown-Menü -->
    <transition name="fade">
      <div v-if="dropdownVisible" class="absolute top-full mt-2 right-0 w-48 bg-white dark:bg-primary-dark border border-gray-200 dark:border-gray-700 dark:text-white rounded-lg shadow-lg">
        <ul>
          <router-link to="/auth/profil">
          <li class="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">Profil bearbeiten</li></router-link>
          <router-link to="/logout">
          <li @click="toggleDropdown" class="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer">Ausloggen</li></router-link>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    closeDropdown(event) {
      // Check if the click is outside of the dropdown and the profile image
      if (!this.$el.contains(event.target)) {
        this.dropdownVisible = false;
      }
    },
    editProfile() {
      // Logik für das Bearbeiten des Profils
      console.log('Profil bearbeiten');
    },
    logout() {
      // Logik für das Ausloggen
      console.log('Ausloggen');
    },
  },
  mounted() {
    // Add event listener for clicks outside the component
    document.addEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped>
/* Dropdown-Effekte */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
