<script>
import { apiClient } from "@/components/config/api.js";

export default {
  name: "add",
  data() {
    return {
      item: {
        name: "",
        label: "",
        url: "https://",
        myurl: false,
        my_shortener_url: "",
      },
      nameError: "",
      labelError: "",
      urlError: "",
      myurlError: "",
      loading: false,
    };
  },
  methods: {
    herfShortUrl() {
      this.$router.push({ name: "addShortUrl" });
    },
    async createShortUrl() {
      // Fehler-Reset
      this.nameError = "";
      this.labelError = "";
      this.urlError = "";

      // Validierung
      if (!this.item.name) {
        this.nameError = "Name ist erforderlich.";
      }
      if (!this.item.label) {
        this.labelError = "Beschreibung ist erforderlich.";
      }

      // Überprüfe, ob die URL ein gültiges Format hat und mit https:// oder http:// beginnt
      if (!this.item.url || !this.validateUrl(this.item.url)) {
        this.urlError = "Bitte geben Sie eine gültige URL ein.";
      } else if (!/^https?:\/\//i.test(this.item.url)) {
        // Wenn die URL kein Protokoll hat, füge 'https://' hinzu
        this.item.url = "https://" + this.item.url;
      }

      // Abbruch bei Fehlern
      if (this.nameError || this.labelError || this.urlError) {
        return;
      }

      this.loading = true;
      try {
        const response = await apiClient.post(`/shorteners`, {
          name: this.item.name,
          label: this.item.label,
          url: this.item.url,
          myurl: this.item.myurl,
          my_shortener_url: this.item.my_shortener_url,
        });

        if (response.status === 200) {
          this.$toast.open({
            message: "Short URL erfolgreich erstellt!",
            type: "success",
            duration: 3000,
          });
          this.$router.push("/auth/activlists/" + response.data.id);
        }
        this.loading = false;
      } catch (error) {
        console.error("Erstellung der Short URL fehlgeschlagen:", error);
        this.loading = false;
        this.$toast.open({
          message:
            error.response.data.message ||
            "Erstellung der Short URL fehlgeschlagen.",
          type: "error",
          duration: 3000,
        });
        this.myurlError = error.response.data.message;
      }
    },
    // URL-Validierung bleibt unverändert
    validateUrl(url) {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // Protokoll
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // Domainname
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // IP-Adresse (IPv4)
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port und Pfad
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // Abfragezeichenfolge
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return !!pattern.test(url);
    },
  },
};
</script>

<template>
  <div>
    <GridCols2>
      <template v-slot:left>
        <div
          class="max-w-sm rounded overflow-hidden shadow-lg bg-white dark:bg-pi cursor-pointer"
        >
          <img
            class="w-full h-48"
            src="https://p.kfzdealer.com/img/landingpage.png"
            alt="Lagerhalle"
            @click="herfShortUrl()"
          />
          <div class="px-6 py-4 text-left">
            <div class="font-bold text-xl mb-2">Short-URL erstellen</div>
            <p class="text-gray-700 text-base">efef</p>
          </div>
          <div class="px-6 pb-2">
            <FormInput
              label="Name"
              inputType="text"
              v-model="item.name"
              :inputIdentifier="'name'"
              :error="nameError"
            />
            <FormInput
              label="Beschreibung"
              inputType="text"
              v-model="item.label"
              :inputIdentifier="'label'"
              :error="labelError"
            />
            <FormInput
              label="Original URL"
              inputType="text"
              v-model="item.url"
              :inputIdentifier="'url'"
              :error="urlError"
            />
            <div class="float-left">
            <FormCheckbox
              inputIdentifier="terms"
              label="URL selber benennen"
              v-model="item.myurl"
              :error="error"
            /></div>
            <div v-if="item.myurl" class="flex gap-2">
              <div class="flex bottom-0">
                <select
                  class="block w-40 px-4 my-2.5 text-base text-gray-900 dark:bg-secondary-dark border-none dark:text-white"
                >
                  <option value="1">www.qrline.io/</option>
                  <option value="2">qr.example.com/</option>
                </select>
              </div>
              <div class="w-full">
                <FormInput
                  label="Deine gewünschte URL-Name"
                  inputType="text"
                  v-model="item.my_shortener_url"
                  :inputIdentifier="'my_shortener_url'"
                  :error="myurlError"
                />
              </div>
            </div>
            <div
              class="pt-10 flex items-center justify-between dark:text-white"
            >
              <div>
                <ButtonDefaultColor @click="createShortUrl">
                  <div v-if="!loading">URL erstellen</div>
                  <div v-else>
                    <div class="flex gap-2 items-center justify-center">
                      <SpinnerWhiteSmall></SpinnerWhiteSmall>Loading...
                    </div>
                  </div>
                </ButtonDefaultColor>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div
          class="max-w-sm rounded overflow-hidden shadow-lg bg-white cursor-pointer"
          @click="herfShortUrl()"
        >
          <img
            class="w-full h-48"
            src="https://p.kfzdealer.com/img/landingpage.png"
            alt="Lagerhalle"
          />
          <div class="px-6 py-4 text-left">
            <div class="font-bold text-xl mb-2">Laingenpage erstellen</div>
            <p class="text-gray-700 text-base">efef</p>
          </div>
          <div class="px-6 pt-4 pb-4">
            <div class="flex justify-between">
              <div></div>
              <div>
                <ButtonDefaultColor>starten</ButtonDefaultColor>
              </div>
            </div>
          </div>
        </div>
      </template>
    </GridCols2>
  </div>
</template>

<style scoped></style>
