<script>
import ModalDeleteBanner from "@/components/reusable/ModalDeleteBanner.vue";
import CardDefault from "@/components/reusable/CardDefault.vue"; // Pfad anpassen
import { apiClient } from "@/components/config/api.js";

export default {
  name: "ShortenerCard",
  components: {
    ModalDeleteBanner,
    CardDefault,
  },
  props: {
    itemsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSortField: null,
      currentSortOrder: "asc",
      showDiv: true, // Zeige die Aktionsspalte nur wenn true
      visibleModalIndex: null, // Zum Verwalten der Sichtbarkeit des Modals
      permissions: {}, // Beispielhafte Berechtigungen
      isSuperAdmin: false, // Beispielhafte Überprüfung, ob der Benutzer ein Super-Admin ist
      isDropdownOpen: null,
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast.open({
            message: "URL wurde erfolgreich in die Zwischenablage kopiert!",
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((err) => {
          console.error("Fehler beim Kopieren in die Zwischenablage:", err);
        });
    },
    sortTable(field) {
      if (this.currentSortField === field) {
        this.currentSortOrder =
          this.currentSortOrder === "asc" ? "desc" : "asc";
      } else {
        this.currentSortField = field;
        this.currentSortOrder = "asc";
      }
      this.$emit("sort", this.currentSortField, this.currentSortOrder);
    },
    getSortIcon(field) {
      if (this.currentSortField === field) {
        return this.currentSortOrder === "asc"
          ? "bi bi-arrow-up"
          : "bi bi-arrow-down";
      }
      return "bi bi-arrow-down-up";
    },
    navigateToShortener(id) {
      this.$router.push({ name: "ShortenerDetails", params: { id } });
    },
    openModal(index) {
      this.visibleModalIndex = index;
      this.isDropdownOpen = null;
    },
    closeModal() {
      this.visibleModalIndex = null;
    },
    deleteid(id, index) {
      apiClient
        .delete(`shorteners/${id}`)
        .then((response) => {
          // Verarbeitung der erfolgreichen Antwort
          console.log("shortener gelöscht", response.data);
          this.removeDealer(index);
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom-right",
          });
        })
        .catch((error) => {
          // Fehlerbehandlung
          console.error("Fehler beim Löschen des shortener:", error);
          this.$toast.open({
            message: error.response.data.message,
            type: "error",
            position: "bottom-right",
          });
        });
    },
    removeDealer(index) {
      this.$emit("pushdelete", index);
      //this.$delete(this.dealers, id);
    },
    toggleDropdown(index) {
      this.isDropdownOpen = this.isDropdownOpen === index ? null : index;
    },
    formatDateTime(date) {
      const optionsDate = { day: "2-digit", month: "2-digit", year: "numeric" };
      const optionsTime = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };

      const dateObj = new Date(date);

      // Formatieren von Datum und Uhrzeit
      const formattedDate = dateObj.toLocaleDateString("de-DE", optionsDate);
      const formattedTime = dateObj.toLocaleTimeString("de-DE", optionsTime);

      return `${formattedDate} ${formattedTime} Uhr`;
    },
  },
};
</script>

<template>
  <div v-for="(item, index) in itemsData" :key="index">
    <CardDefault class="my-5">
      <template v-slot:header-left><div class="cursor-pointer" @click="navigateToShortener(item._id)">{{ item.name }}</div></template>
      <template v-slot:header-right>
        <div class="relative inline-flex">
          <button
            @click="toggleDropdown(index)"
            type="button"
            class="p-2 inline-flex justify-center items-center gap-2 rounded-full font-medium text-center text-pink-light bg-customColor-500 hover:bg-customColorHover-600 focus:ring-4 focus:outline-none focus:ring-customColor-500 dark:hover:bg-customColorHover-600 dark:focus:bg-customColor-500 w-8 h-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-three-dots"
              viewBox="0 0 16 16"
            >
              <path
                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
              />
            </svg>
          </button>

          <div
            v-if="isDropdownOpen === index"
            class="absolute right-0 top-full mt-2 transition-opacity duration-200 opacity-100 min-w-[15rem] z-50 bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700"
          >
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
              @click="openModal(index)"
            >
              {{ $t("Button_Delete") }}
            </a>
            <a
              class="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
              @click="navigateToShortener(item._id)"
            >
              {{ $t("Button_Edit") }}
            </a>
          </div>
        </div>
        <ModalDeleteBanner
          v-if="visibleModalIndex === index"
          :id="item._id"
          @close="closeModal"
          @confirm="deleteid(item._id, index)"
      /></template>
      <template v-slot:main>
        <div class="flex gap-2 dark:text-white cursor-pointer">
          <i class="bi bi-speedometer text-lg mr-2"></i>
          <p> {{ item.statistics_count }} Klicks</p>
        </div>
        <div
          class="flex gap-2 dark:text-white cursor-pointer"
          @click="
            copyToClipboard(`https://link.kfzdealer.com/${item.shortener_url}`)
          "
        >
          <i class="bi bi-copy text-lg mr-2"></i>
          <p>https://link.kfzdealer.com/{{ item.shortener_url }}</p>
        </div></template
      >
    </CardDefault>
  </div>
</template>

<style scoped></style>
