<script>
export default {
  name: "Pricing",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<template>
  <div class="container mx-auto px-0 pb-10">
    <h2 class="text-3xl font-bold text-center dark:text-white py-4">
      Unsere Pakete
    </h2>
    <div class="mb-8">
      <FormCheckbox inputIdentifier="terms" label="Jährlich" :error="error" />
    </div>

    <!-- Table Layout für größere Bildschirme -->
    <div class="hidden lg:block">
      <table
        class="min-w-full bg-white dark:bg-secondary-dark shadow-md rounded-lg overflow-hidden"
      >
        <thead>
          <tr>
            <th
              class="px-6 py-4 text-left text-md font-medium text-gray-900 dark:text-white"
            >
              Pakete
            </th>
            <th
              class="px-6 py-4 text-center text-lg font-medium text-gray-900 dark:text-white"
            >
              Basic
            </th>
            <th
              class="px-6 py-4 text-center text-lg font-medium text-gray-900 dark:text-white"
            >
              Essential
            </th>
            <th
              class="px-6 py-4 text-center text-lg font-medium text-gray-900 dark:text-white"
            >
              Professional
            </th>
            <th
              class="relative px-6 py-4 text-center text-lg font-medium text-white bg-pink-dark rounded-lg"
            >
              Business
            </th>
            <th
              class="px-6 py-4 text-center text-lg font-medium text-gray-900 dark:text-white"
            >
              Enterprise
            </th>
          </tr>
        </thead>
        <tbody
          class="divide-y divide-gray-200 dark:divide-gray-700 dark:text-white"
        >
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              14 Tage Testphase
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              Dynamische Links
            </td>
            <td class="px-6 py-4 text-center">10</td>
            <td class="px-6 py-4 text-center">50</td>
            <td class="px-6 py-4 text-center">100</td>
            <td class="px-6 py-4 text-center">500</td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              Statistikprotokoll
            </td>
            <td class="px-6 py-4 text-center">-</td>
            <td class="px-6 py-4 text-center">30 Tage</td>
            <td class="px-6 py-4 text-center">90 Tage</td>
            <td class="px-6 py-4 text-center">90 Tage</td>
            <td class="px-6 py-4 text-center">Individuell</td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              API Schnittstelle
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              Individuell Links
            </td>
            <td class="px-6 py-4 text-center">-</td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              Deine Domain
            </td>
            <td class="px-6 py-4 text-center">-</td>
            <td class="px-6 py-4 text-center">-</td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td
              class="px-6 py-4 text-center"
            >
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300 text-left">
              Benutzer
            </td>
            <td class="px-6 py-4 text-center">1</td>
            <td class="px-6 py-4 text-center">5</td>
            <td class="px-6 py-4 text-center">10</td>
            <td class="px-6 py-4 text-center">50</td>
            <td class="px-6 py-4 text-center">
              <i class="bi bi-check-lg text-lg text-green-500"></i>
            </td>
          </tr>
          <tr>
            <td class="px-6 py-4 text-gray-700 dark:text-gray-300"></td>
            <td class="px-6 py-4 text-center">
              4,99€
              <p>monatlich</p>
            </td>
            <td class="px-6 py-4 text-center">
              14,99€
              <p>monatlich</p>
            </td>
            <td class="px-6 py-4 text-center">
              49,99€
              <p>monatlich</p>
            </td>
            <td class="px-6 py-4 text-center">
              199,99€
              <p>monatlich</p>
            </td>
            <td class="px-6 py-4 text-center">Individuell</td>
            <!-- Weitere Features hinzufügen -->
          </tr>

          <tr>
            <td class="px-6 py-4"></td>
            <td class="px-6 py-4 text-center">
              <ButtonDefaultColor>Buchen</ButtonDefaultColor>
            </td>
            <td class="px-6 py-4 text-center">
              <ButtonDefaultColor>Buchen</ButtonDefaultColor>
            </td>
            <td class="px-6 py-4 text-center">
              <ButtonDefaultColor>Buchen</ButtonDefaultColor>
            </td>
            <td class="px-6 py-4 text-center">
              <ButtonDefaultColor>Buchen</ButtonDefaultColor>
            </td>
            <td class="px-6 py-4 text-center">
              <ButtonDefaultColor>Buchen</ButtonDefaultColor>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Cards Layout für mobile und Tablets -->
    <div class="lg:hidden grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Paket 1 -->
      <div
        class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 text-center"
      >
        <h3 class="text-xl font-semibold mb-4">Paket 1</h3>
        <ul class="text-left mb-4">
          <li class="text-gray-700 dark:text-gray-300 mb-2">✔️ Feature 1</li>
          <li class="text-gray-700 dark:text-gray-300 mb-2">✔️ Feature 2</li>
          <!-- Weitere Features hinzufügen -->
        </ul>
        <p class="text-2xl font-bold mb-6">€29.99</p>
        <button
          class="bg-pink-500 hover:bg-pink-600 text-white font-semibold py-2 px-4 rounded"
        >
          Jetzt buchen
        </button>
      </div>
      <!-- Paket 2 -->
      <div
        class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-6 text-center"
      >
        <h3 class="text-xl font-semibold mb-4">Paket 2</h3>
        <ul class="text-left mb-4">
          <li class="text-gray-700 dark:text-gray-300 mb-2">✔️ Feature 1</li>
          <li class="text-gray-700 dark:text-gray-300 mb-2">✔️ Feature 2</li>
          <!-- Weitere Features hinzufügen -->
        </ul>
        <p class="text-2xl font-bold mb-6">€49.99</p>
        <button
          class="bg-pink-500 hover:bg-pink-600 text-white font-semibold py-2 px-4 rounded"
        >
          Jetzt buchen
        </button>
      </div>
      <!-- Weitere Pakete hinzufügen -->
    </div>
  </div>
</template>

<style scoped></style>
