<template>
  <div class="grid grid-cols-1 gap-6 md:grid-cols-1 lg:grid-cols-2 mb-5">
    <slot name="main"></slot>
    <div class="basis-1/2 flex flex-col">
      <slot name="left"></slot>
    </div>
    <div class="basis-1/2 flex flex-col">
      <slot name="right"></slot>
    </div>
  </div>
  <slot name="footer"></slot>
</template>

<script>
export default {
  name: "GridCols2",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
