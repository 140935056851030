<script>
//import AboutMe from '@/components/about/AboutMe';
import Navbar from "@/components/shared/auth/Navbar.vue";
import SideNavbar from "@/components/shared/auth/SideNavbar.vue";
import feather from "feather-icons";

export default {
  name: "authAuthLayout",
  components: {
    //AboutMe,
    Navbar,
    SideNavbar,
  },
  data: () => {
    return {
      appTheme: localStorage.getItem("theme"),
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
  methods: {
    toggleSidebar() {
      this.$refs.sidebar.toggleSidebar();
    },
  },
};
</script>

<template>
  <div :class="appTheme">
    <header></header>
    <main>
      <Navbar @toggle-sidebar="toggleSidebar"></Navbar>
      <SideNavbar ref="sidebar"></SideNavbar>
      <!-- Dieser Bereich zeigt die Kinderkomponenten von AuthLayout an -->
      <div class="mt-28 mx-4 md:ml-72 md:mr-8">
        <transition name="fade" mode="out-in">
          <router-view :theme="appTheme" />
        </transition>
      </div>
    </main>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.vue-back-to-top {
  @apply p-2 bg-pink-light hover:bg-pink-dark text-white transition
        duration-500
        ease-in-out
        transform
        hover:-translate-y-1 hover:scale-110;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
}

.fade-enter-active {
  animation: coming 0.4s;
  animation-delay: 0.2s;
  opacity: 0;
}

.fade-leave-active {
  animation: going 0.4s;
}

@keyframes going {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-10px);
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
