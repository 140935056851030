<template>
  <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 mb-5">
    <slot name="main"></slot>
    <div class="basis-1/4">
      <slot name="one"></slot>
    </div>
    <div class="basis-1/4">
      <slot name="two"></slot>
    </div>
    <div class="basis-1/4">
      <slot name="three"></slot>
    </div>
    <div class="basis-1/4">
      <slot name="four"></slot>
    </div>
  </div>
  <slot name="footer"></slot>
</template>

<script>
export default {
  name: "GridCols4",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
