<template>
  <div class="flex justify-center items-center mt-32">
    <div>
      <SpinnerColor />
      <p
        class="font-general-medium mt-2 text-lg sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-gray-400"
      >
        Sie werden ausgeloogt
      </p>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/components/config/api.js";
import Cookies from "js-cookie";
export default {
  name: "LogOut",
  async created() {
    try {
      const response = await apiClient.post("logout/");
      Cookies.remove("UserId");
      Cookies.remove("auth_check");
      Cookies.remove("refresh_token");
      localStorage.removeItem("userpic");
      localStorage.removeItem("cartCustomer");
      localStorage.removeItem("cart");
      this.$router.push("/login");
      console.log(response.data.message);
    } catch (error) {
      console.log(error);
      Cookies.remove("UserId");
      Cookies.remove("auth_check");
      Cookies.remove("refresh_token");
      this.$router.push("/");
      localStorage.removeItem("userpic");
      localStorage.removeItem("cartCustomer");
      localStorage.removeItem("cart");
    }
  },
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
