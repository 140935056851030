<script>
export default {
  props: {
    // Du kannst weiterhin zusätzliche Attribute übergeben
    attributes: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<template>
  <button 
  type="button"
  class="text-md font-general-medium border-2 border-pink-light hover:border-pink-dark hover:bg-pink-dark text-pink-light hover:text-white shadow-sm rounded-md px-5 py-2.5 duration-300"
  v-bind="attributes">
    <slot></slot> <!-- Hier wird der dynamische Inhalt eingefügt -->
  </button>
</template>

<style lang="scss" scoped></style>