<script>
export default {
  name: "NotFound",
};
</script>

<template>
  <div class="not-found">
    <h1>404 - Seite nicht gefunden</h1>
    <p>Die angeforderte Seite existiert nicht.</p>
    <router-link to="/">Zurück zur Startseite</router-link>
  </div>
</template>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 50px;
}
</style>
