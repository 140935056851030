<template>
  <nav
    class="fixed top-0 left-0 w-full bg-secondary-light dark:bg-secondary-dark shadow-md z-10"
  >
    <div class="flex justify-between items-center py-2 px-4">
      <!-- Logo -->
      <div class="flex md:hidden">
        <router-link to="/auth/">
          <img
            v-if="theme === 'light'"
            src="@/assets/images/Logo-white.png"
            class="w-36"
            alt="Logo Light"
          />
          <img
            v-else
            src="@/assets/images/Logo-dark.png"
            class="w-36"
            alt="Logo Dark"
          />
        </router-link>
      </div>

      <Search></Search>

      <!-- Navbar Links and Actions -->
      <div class="flex mr-12 md:mr-2">
        <div class="hidden md:flex items-center space-x-4">
          <theme-switcher
            :theme="theme"
            @themeChanged="updateTheme"
            class="p-4"
          />
        </div>
        <DropdownUser />
      </div>
    </div>
  </nav>
</template>

<script>
import ThemeSwitcher from "../../ThemeSwitcher.vue";
import DropdownUser from "./DropdownUser.vue";
import Search from "../../shared/auth/Search.vue";

export default {
  components: {
    ThemeSwitcher,
    DropdownUser,
    Search,
  },
  data() {
    return {
      theme: "",
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  methods: {
    updateTheme(theme) {
      this.theme = theme;
      localStorage.setItem("theme", theme);
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
  },
};
</script>
